define('distorted-portal/components/programme/detail-crumbs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    // Services
    session: Ember.inject.service()
  }).reopenClass({
    positionalParams: ['title']
  });
});