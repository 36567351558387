define('distorted-portal/controllers/internal/programme/shows', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable'], function (exports, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controller
    programmeController: Ember.inject.controller('internal.programme'),
    // Attributes
    pageTitle: "Programme Shows"
  });
});