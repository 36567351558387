define('distorted-portal/controllers/internal/latest', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pageTitle: 'Latest Shows',
    sort: 'title',
    grid: true,
    latestShows: Ember.computed('model', function () {
      var shows = [];

      Ember.get(this, 'model').forEach(function (syndication) {
        Ember.get(syndication, 'programme.shows').forEach(function (show) {
          if (Ember.get(show, 'isNew')) {
            shows.pushObject(show);
          }
        });
      });

      return shows;
    })
  });
});