define('distorted-portal/utils/sort-countries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = sortCountries;
  function sortCountries(countries) {

    // custom countries
    var topCountries = ['United Kingdom',
    // 'United Kingdom of Great Britain and Northern Ireland',
    'United States',
    // 'United States of America',
    'Germany', 'France', 'Russian Federation', 'Australia', 'Canada'];

    countries = countries.toArray();

    // clense long names
    countries.forEach(function (country) {
      if (Ember.get(country, 'name') === "United Kingdom of Great Britain and Northern Ireland") {
        Ember.set(country, 'name', 'United Kingdom');
      }
      if (Ember.get(country, 'name') === "United States of America") {
        Ember.set(country, 'name', 'United States');
      }
    });

    // sort by name
    return countries.sort(function (aObj, bObj) {
      var a = Ember.get(aObj, 'name'),
          b = Ember.get(bObj, 'name'),

      // is a in top array
      aIndex = topCountries.indexOf(a),
          aTopCountry = aIndex !== -1,

      // is b in top array
      bIndex = topCountries.indexOf(b),
          bTopCountry = bIndex !== -1;

      // both in topCountries
      if (aTopCountry && bTopCountry) {
        return aIndex - bIndex;
      } else if (aTopCountry) {
        return -1;
      } else if (bTopCountry) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
  }
});