define('distorted-portal/routes/internal/syndication/shows', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable'], function (exports, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);

      params.page = page;
      params.order = sort;
      // params.include = 'programme.client';

      // Parent route has the syndication id but we need the programme id to
      // filter shows. As the parent route has already loaded the syndication
      // model at this point we can peak into the store and access the programme
      // id safely
      var syndicationId = this.paramsFor('internal.syndication').programme_syndicator_id,
          syndication = this.store.peekRecord('programme-syndicator', syndicationId);

      params.programme_id = Ember.get(syndication, 'programme.id');

      return this.store.query('show', params);
    }
  });
});