define('distorted-portal/components/uxs-list', ['exports', 'ember-ux-sauce/components/uxs-list'], function (exports, _uxsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsList.default;
    }
  });
});