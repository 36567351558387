define('distorted-portal/mirage/config', ['exports', 'distorted-portal/mirage/helpers/session-handlers', 'distorted-portal/mirage/helpers/responses'], function (exports, _sessionHandlers, _responses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    // make this `http://localhost:8080`, for example, if your API is on a different server
    this.urlPrefix = 'http://localhost:4000';
    // make this `api`, for example, if your API is namespaced
    this.namespace = 'api/v1';
    // delay for each request, automatically set to 0 during testing
    this.timing = 400;

    // Authentication
    this.get('/current_user', _sessionHandlers.getSession);
    this.post('/users/sign_in', _sessionHandlers.postSession);
    this.delete('/users/sign_out', _sessionHandlers.deleteSession);

    // Passwords
    this.post('/recover_password', function () {
      return (0, _responses.successResponse)(202);
    });
    this.put('/reset_password', function () {
      return (0, _responses.successResponse)(204);
    });

    // Users
    this.get('/users');
    this.post('/users');
    this.get('/users/:id');
    this.put('/users/:id');
    this.patch('/users/:id');
    this.delete('/users/:id');

    // Clients
    this.get('/clients');
    this.post('/clients');
    this.get('/clients/:id');
    this.put('/clients/:id');
    this.patch('/clients/:id');
    this.delete('/clients/:id');

    // Syndicators
    this.get('/syndicators');
    this.post('/syndicators');
    this.get('/syndicators/:id');
    this.put('/syndicators/:id');
    this.patch('/syndicators/:id');
    this.delete('/syndicators/:id');

    // Podcasts
    this.get('/podcasts');
    this.post('/podcasts');
    this.get('/podcasts/:id');
    this.put('/podcasts/:id');
    this.patch('/podcasts/:id');
    this.delete('/podcasts/:id');

    // Episodes
    this.get('/episodes');
    this.post('/episodes');
    this.get('/episodes/:id');
    this.put('/episodes/:id');
    this.patch('/episodes/:id');
    this.delete('/episodes/:id');

    // Programme
    this.get('/programmes');
    this.post('/programmes');
    this.get('/programmes/:id');
    this.put('/programmes/:id');
    this.patch('/programmes/:id');
    this.delete('/programmes/:id');

    // Programme Syndicator
    this.get('/programme-syndicators');
    this.post('/programme-syndicators');
    this.get('/programme-syndicators/:id');
    this.put('/programme-syndicators/:id');
    this.patch('/programme-syndicators/:id');
    this.del('/programme-syndicators/:id');

    // Show
    this.get('/shows');
    this.post('/shows');
    this.get('/shows/:id');
    this.put('/shows/:id');
    this.patch('/shows/:id');
    this.delete('/shows/:id');

    // Categories
    this.get('/categories');
    this.post('/categories');
    this.get('/categories/:id');
    this.put('/categories/:id');
    this.patch('/categories/:id');
    this.delete('/categories/:id');

    // Languages
    this.get('/languages');
    this.get('/languages/:id');

    // Countries
    this.get('/countries');
    this.get('/countries/:id');

    this.get('/podcasts-data/audio-data-by-day', function () {
      return [["2018-03-05", 26433.331311588176], ["2018-03-06", 26034.5224048933], ["2018-03-07", 28362.352098846808], ["2018-03-08", 28530.623123007827], ["2018-03-09", 31082.195581145585], ["2018-03-10", 23764.794375081547]];
    });
    this.get('/podcasts-data/audio-traffic-by-day', function () {
      return [];
    });
    this.get('/podcasts-data/audio-data-by-country', function () {
      return [["United States", 850206], ["United Kingdom", 844521], ["Germany", 348704], ["Russia", 217036], ["Australia", 186000], ["France", 134283], ["Netherlands", 120599], ["Canada", 108413], ["Spain", 86289], ["Mexico", 80584], ["Japan", 79381], ["Switzerland", 76346], ["Italy", 60834], ["Belgium", 44187], ["Sweden", 35046], ["Pakistan", 33229], ["China", 30191]];
    });
    this.get('/podcasts-data/audio-data-by-device', function () {
      return [["smartphone", 3465712], ["tablet", 185986], ["unknown", 124713], ["desktop", 124672], ["portable media player", 35551], ["tv", 1129], ["phablet", 772], ["console", 3], ["feature phone", 1]];
    });
    this.get('/podcasts-data/audio-data-by-device-type', function () {
      return [["smartphone", 3465712], ["tablet", 185986], ["unknown", 124713], ["desktop", 124672], ["portable media player", 35551], ["tv", 1129], ["phablet", 772], ["console", 3], ["feature phone", 1]];
    });
  };
});