define('distorted-portal/models/user', ['exports', 'ember-data', 'distorted-portal/mixins/validations/user', 'distorted-portal/utils/const/user'], function (exports, _emberData, _user, _user2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_user.default, {
    // Attributes
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    contactNumber: attr('string'),
    role: attr('string'),
    lastLoginDate: attr('date'),
    password: attr('string'),
    newPassword: attr('string'),
    // Relationships
    client: belongsTo(),
    syndicator: belongsTo(),
    // Computed
    name: Ember.computed('firstName', 'lastName', function () {
      return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
    }),
    avatar: Ember.computed(function () {
      return 'https://eu.ui-avatars.com/api/?name=' + Ember.get(this, 'firstName') + '+' + Ember.get(this, 'lastName') + '&background=D500F9&color=fff';
    }),
    isAdmin: Ember.computed.equal('role', _user2.ADMIN_ROLE),
    isClient: Ember.computed.equal('role', _user2.CLIENT_ROLE),
    isSyndicator: Ember.computed.equal('role', _user2.SYNDICATOR_ROLE)
  });
});