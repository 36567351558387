define('distorted-portal/mirage/factories/programme', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    author: function author() {
      return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
    },
    copyright: function copyright() {
      return _emberCliMirage.faker.company.companyName() + ' ' + _emberCliMirage.faker.date.past().getYear();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraphs(2);
    },
    image: function image() {
      return _emberCliMirage.faker.image.imageUrl(320, 320, undefined, true);
    },
    subTitle: function subTitle() {
      return _emberCliMirage.faker.hacker.phrase();
    },
    summary: function summary() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    title: function title() {
      return (_emberCliMirage.faker.hacker.verb() + ' ' + _emberCliMirage.faker.hacker.noun()).capitalize();
    },
    totalShows: function totalShows() {
      return _emberCliMirage.faker.random.number({
        min: 0,
        max: 99
      });
    },
    totalSyndicators: function totalSyndicators() {
      return _emberCliMirage.faker.random.number({
        min: 0,
        max: 99
      });
    },
    deletedAt: function deletedAt() {
      return null;
    },

    // Traits
    withClient: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(programme, server) {
        var clients = server.schema.clients.all().models,
            client = _emberCliMirage.faker.random.arrayElement(clients);
        programme.update({
          client: client
        });
      }
    }),
    withCategory: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(programme, server) {
        var categories = server.schema.categories.all().models,
            category = _emberCliMirage.faker.random.arrayElement(categories);
        programme.update({
          category: category
        });
      }
    }),
    withLanguage: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(programme, server) {
        var languages = server.schema.languages.all().models,
            language = _emberCliMirage.faker.random.arrayElement(languages);
        programme.update({
          language: language
        });
      }
    }),
    withSyndications: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(programme, server) {
        var syndicators = server.schema.syndicators.all().models,
            randomSyndicators = getRandomArrayElements(syndicators, _emberCliMirage.faker.random.number({
          min: 1,
          max: 4
        }));

        randomSyndicators.forEach(function (syndicator) {
          server.create('programme-syndicator', {
            syndicator: syndicator,
            programme: programme
          });
        });
      }
    })
  });


  function getRandomArrayElements(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len) throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
});