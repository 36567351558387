define('distorted-portal/mixins/validations/audio-group', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    audioGroupFiles: [(0, _emberCpValidations.validator)('has-many', true)]
  });
});