define('distorted-portal/routes/internal/show/dashboard', ['exports', 'distorted-portal/mixins/routes/dashboardable'], function (exports, _dashboardable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_dashboardable.default, {
    model: function model(params) {

      params = this.getRouteParams(params);

      // add show id
      params['show_id'] = this.paramsFor('internal.show').show_id;

      return this.getProgrammeData(params);
    }
  });
});