define('distorted-portal/controllers/application', ['exports', 'distorted-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Attributes
    name: _environment.default.APP.name
  });
});