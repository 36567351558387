define('distorted-portal/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    // server.loadFixtures('users');
    server.loadFixtures('languages');
    server.loadFixtures('countries');
    server.loadFixtures('categories');

    server.create('user', 'asAdmin', {
      firstName: 'Jim',
      lastName: 'Wardlaw',
      email: 'jim@wearesauce.io'
    });

    var client = server.create('client');

    server.create('user', 'asClient', {
      firstName: 'Jim',
      lastName: 'Wardlaw',
      email: 'jim+client@wearesauce.io',
      client: client
    });

    var syndicator = server.create('syndicator');

    server.create('user', 'asSyndicator', {
      firstName: 'Jim',
      lastName: 'Wardlaw',
      email: 'jim+syndicator@wearesauce.io',
      syndicator: syndicator
    });

    server.createList('client', 10);

    server.createList('syndicator', 10);

    server.createList('user', 5, 'asAdmin');
    server.createList('user', 5, 'asClient', {
      client: client
    });
    server.createList('user', 5, 'asSyndicator');

    server.createList('category', 10);
    server.createList('podcast', 20, 'withClient', 'withCategory', 'withLanguage');
    server.createList('episode', 20, 'withPodcast', 'withCategory', 'withLanguage');
    // server.create('episode', 'withPodcast', 'withCategory', 'withLanguage', {
    //   deletedAt: moment().subtract(2, 'days').format(),
    // });
    server.createList('programme', 20, 'withClient', 'withCategory', 'withLanguage', 'withSyndications');
    server.createList('show', 20, 'withProgramme', 'withCategory', 'withLanguage', 'withAudioGroups');
  };
});