define('distorted-portal/models/syndicator', ['exports', 'ember-data', 'distorted-portal/mixins/validations/syndicator', 'distorted-portal/utils/const/syndicator'], function (exports, _emberData, _syndicator, _syndicator2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_syndicator.default, {
    // Service
    countryService: Ember.inject.service(),
    // Attributes
    weeklyReach: attr('string'),
    website: attr('string'),
    twitter: attr('string'),
    platform: attr('string'),
    name: attr('string'),
    image: attr('string'),
    facebook: attr('string'),
    description: attr('string'),
    countryCode: attr('string'),
    city: attr('string'),
    totalSyndications: attr('number'),
    notes: attr('string'),
    // Relationships
    users: hasMany('user'),
    syndications: hasMany('programme-syndicator'),
    // Computed
    location: Ember.computed('city', 'countryCode', function () {
      var country = Ember.get(this, 'countryName'),
          city = Ember.get(this, 'city');

      if (city) {
        return city + ', ' + country;
      }
      return country;
    }),
    countryName: Ember.computed('countryCode', function () {
      return Ember.get(this, 'countryService').nameForCode(Ember.get(this, 'countryCode'));
    }),
    platformStyle: Ember.computed('platform', function () {
      var platform = Ember.get(this, 'platform');

      switch (platform) {
        case _syndicator2.PLATFORM_FM:
          return 'success';
        case _syndicator2.PLATFORM_DIGITAL:
          return 'accent';
        case _syndicator2.PLATFORM_INTERNET:
          return 'primary';
        case _syndicator2.PLATFORM_OTHER:
        default:
          return 'mid';
      }
    }),
    totalSyndicationsStyle: Ember.computed('totalSyndications', function () {
      var totalSyndications = Ember.get(this, 'totalSyndications');

      if (totalSyndications > 9) {
        return 'success';
      }
      if (totalSyndications > 4) {
        return 'accent';
      }
      if (totalSyndications > 0) {
        return 'primary';
      }

      return 'mid';
    }),
    avatar: Ember.computed('platform', function () {
      switch (Ember.get(this, 'platform')) {
        case 'fm':
          return "/images/icon.fm-radio.png";
        case 'digital':
          return "/images/icon.digital-radio.png";
        case 'internet':
          return "/images/icon.internet-radio.png";
        default:
          return "/images/icon.misc.png";
      }
    })
  });
});