define('distorted-portal/helpers/file-from-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileFromUrl = fileFromUrl;
  function fileFromUrl(params /*, hash*/) {
    var url = params[0];
    return url.substring(url.lastIndexOf('/') + 1);
  }

  exports.default = Ember.Helper.helper(fileFromUrl);
});