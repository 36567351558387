define('distorted-portal/components/uxs-table-row', ['exports', 'ember-ux-sauce/components/uxs-table-row'], function (exports, _uxsTableRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsTableRow.default;
    }
  });
});