define('distorted-portal/mirage/helpers/responses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorResponse = errorResponse;
  exports.successResponse = successResponse;
  function errorResponse(errors) {
    return new Response(422, {
      'Content-Type': 'application/vnd.api+json'
    }, {
      errors: errors
    });
  }

  function successResponse(status, data) {
    return new Response(status, {
      'Content-Type': 'application/vnd.api+json'
    }, data);
  }
});