define('distorted-portal/mixins/validations/episode', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    author: [],
    podcast: [(0, _emberCpValidations.validator)('presence', true)],
    category: [(0, _emberCpValidations.validator)('presence', true)],
    copyright: [],
    description: [(0, _emberCpValidations.validator)('presence', true)],
    image: [],
    language: [(0, _emberCpValidations.validator)('presence', true)],
    summary: [(0, _emberCpValidations.validator)('presence', true)],
    title: [(0, _emberCpValidations.validator)('presence', true)]
  });
});