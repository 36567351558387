define('distorted-portal/models/language', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    // Attributes
    name: attr('string'),
    code: attr('string'),
    // Relationships
    podcasts: hasMany(),
    programme: hasMany(),
    shows: hasMany()
    // episodes: hasMany(),
  });
});