define('distorted-portal/components/user-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    defaultRoute: 'internal.user.index',
    // Computed
    syndicatorId: Ember.computed.alias('syndicator.id'),
    clientId: Ember.computed.alias('client.id'),
    hasSyndicator: Ember.computed.bool('syndicatorId'),
    hasClient: Ember.computed.bool('clientId'),
    queryParams: Ember.computed('clientId', 'syndicatorId', function () {

      var params = {},
          clientId = Ember.get(this, 'clientId'),
          syndicatorId = Ember.get(this, 'syndicatorId');

      if (clientId) {
        params.clientId = clientId;
      }

      if (syndicatorId) {
        params.syndicatorId = syndicatorId;
      }

      return params;
    })
  });
});