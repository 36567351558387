define('distorted-portal/mixins/components/printable-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    printableData: '',
    // Actions
    actions: {
      createPNGLink: function createPNGLink(chart) {
        var _this = this;

        google.visualization.events.addListener(chart, 'ready', function () {
          var printableData = chart.getImageURI();
          Ember.set(_this, 'printableData', printableData);
        });
      },
      printChart: function printChart(printableData) {
        window.open(printableData);
      }
    }
  });
});