define('distorted-portal/utils/const/syndicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Name',
    value: 'name'
  }, {
    label: 'Country',
    value: 'country_code'
  }, {
    label: 'Platform',
    value: 'platform'
  }, {
    label: 'Audience',
    value: 'audience'
  }];
  var SYNDICATOR_REACH = exports.SYNDICATOR_REACH = [{
    value: 10000,
    label: 'Under 10,000'
  }, {
    value: 50000,
    label: '10,000 to 50,000'
  }, {
    value: 100000,
    label: '50,000 to 100,000'
  }, {
    value: 250000,
    label: '100,000 to 250,000'
  }, {
    value: 500000,
    label: '250,000 to 500,000'
  }, {
    value: 1000000,
    label: '500,000 to 1 Million'
  }, {
    value: 5000000,
    label: 'Over 1 Million'
  }];
  var SYNDICATOR_TIMES = exports.SYNDICATOR_TIMES = [{
    label: '00:00',
    value: '00:00:00'
  }, {
    label: '01:00',
    value: '01:00:00'
  }, {
    label: '02:00',
    value: '02:00:00'
  }, {
    label: '03:00',
    value: '03:00:00'
  }, {
    label: '04:00',
    value: '04:00:00'
  }, {
    label: '05:00',
    value: '05:00:00'
  }, {
    label: '06:00',
    value: '06:00:00'
  }, {
    label: '07:00',
    value: '07:00:00'
  }, {
    label: '08:00',
    value: '08:00:00'
  }, {
    label: '09:00',
    value: '09:00:00'
  }, {
    label: '10:00',
    value: '10:00:00'
  }, {
    label: '11:00',
    value: '11:00:00'
  }, {
    label: '12:00',
    value: '12:00:00'
  }, {
    label: '13:00',
    value: '13:00:00'
  }, {
    label: '14:00',
    value: '14:00:00'
  }, {
    label: '15:00',
    value: '15:00:00'
  }, {
    label: '16:00',
    value: '16:00:00'
  }, {
    label: '17:00',
    value: '17:00:00'
  }, {
    label: '18:00',
    value: '18:00:00'
  }, {
    label: '19:00',
    value: '19:00:00'
  }, {
    label: '20:00',
    value: '20:00:00'
  }, {
    label: '21:00',
    value: '21:00:00'
  }, {
    label: '22:00',
    value: '22:00:00'
  }, {
    label: '23:00',
    value: '23:00:00'
  }];
  var SYNDICATOR_DAYS = exports.SYNDICATOR_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  var PLATFORM_FM = exports.PLATFORM_FM = 'fm';
  var PLATFORM_DIGITAL = exports.PLATFORM_DIGITAL = 'digital';
  var PLATFORM_INTERNET = exports.PLATFORM_INTERNET = 'internet';
  var PLATFORM_OTHER = exports.PLATFORM_OTHER = 'other';
  var SYNDICATOR_PLATFORMS = exports.SYNDICATOR_PLATFORMS = [PLATFORM_FM, PLATFORM_DIGITAL, PLATFORM_INTERNET, PLATFORM_OTHER];

  var ACTIONS = exports.ACTIONS = [{
    title: 'View Syndicator',
    route: 'internal.syndicator.index',
    name: 'view',
    ability: 'view syndicator'
  }, {
    title: 'Edit Syndicator',
    route: 'internal.syndicator.edit',
    name: 'edit',
    ability: 'update syndicator'
  }, {
    title: 'Syndicator Users',
    route: 'internal.syndicator.users',
    name: 'users',
    ability: 'update syndicator'
  }, {
    title: 'Syndicator Programmes',
    route: 'internal.syndicator.syndications',
    name: 'syndications',
    ability: 'update syndicator'
  }, {
    title: 'Archive Syndicator',
    route: 'internal.syndicator.archive',
    name: 'archive',
    ability: 'archive syndicator'
  }];
});