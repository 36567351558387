define('distorted-portal/controllers/internal', ['exports', 'distorted-portal/config/environment', 'ember-can'], function (exports, _environment, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberCan.CanMixin, {
    // Services
    session: Ember.inject.service(),
    // Attributes
    name: _environment.default.APP.name,
    _menuItems: Ember.A([{
      label: "Dashboard",
      route: "internal.dashboard",
      name: "dashboard",
      ability: "read podcast"
    }, {
      label: "Latest",
      route: "internal.latest",
      name: "latest",
      ability: "only read syndication"
    }, {
      label: "My Programmes",
      route: "internal.syndications",
      name: "syndications",
      ability: "only read syndication",
      currentWhen: "internal.syndications internal.syndication internal.show"
    }, {
      label: "Users",
      route: "internal.users",
      currentWhen: "internal.users internal.user",
      name: "users",
      ability: "read user"
    }, {
      label: "Clients",
      route: "internal.clients",
      currentWhen: "internal.clients internal.client",
      name: "clients",
      ability: "read client"
    }, {
      label: "Syndicators",
      route: "internal.syndicators",
      currentWhen: "internal.syndicators internal.syndicator",
      name: "syndicators",
      ability: "read syndicator"
    }, {
      label: "Podcasts",
      route: "internal.podcasts",
      currentWhen: "internal.podcasts internal.podcast internal.podcast.episodes internal.episode",
      name: "podcasts",
      ability: "read podcast"
    }, {
      label: "Programmes",
      route: "internal.programmes",
      currentWhen: "internal.programmes internal.programme internal.programme.shows internal.show",
      name: "programmes",
      ability: "read programme"
    }]
    // {
    //   label: "Account",
    //   route: "internal.account",
    //   name: "account",
    // }, {
    //   label: "Logout",
    //   route: "internal.logout",
    //   name: "logout",
    // }
    ),
    // Computed
    menuItems: Ember.computed('_menuItems', 'session.currentUser.role', function () {
      var _this = this;

      var items = [];
      Ember.get(this, '_menuItems').filter(function (item) {
        var ability = Ember.get(item, 'ability');
        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            items.pushObject(item);
          }
        } else {
          items.pushObject(item);
        }
      });
      return items;
    }),
    account: Ember.computed.alias('session.currentUser')
  });
});