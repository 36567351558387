define('distorted-portal/mixins/controllers/audiouploadable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      audioFileUpdated: function audioFileUpdated(model, audioFileName, audioTrackLength, audioFileSize) {
        var toast = Ember.get(this, 'toast');
        toast.info('Audio File Uploaded. Click Save to update the database');
        Ember.set(model, 'audioFileName', audioFileName);
        Ember.set(model, 'audioTrackLength', audioTrackLength);
        Ember.set(model, 'audioFileSize', audioFileSize);
      },
      audioFileError: function audioFileError(model, message) {
        var toast = Ember.get(this, 'toast');
        toast.info(message);
      }
    }
  });
});