define('distorted-portal/components/chart/podcast-daily-traffic', ['exports', 'distorted-portal/mixins/components/downloadable-chart', 'distorted-portal/mixins/components/printable-chart', 'distorted-portal/mixins/components/dashboard-chart'], function (exports, _downloadableChart, _printableChart, _dashboardChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dashboardChart.default, _downloadableChart.default, _printableChart.default, {
    // Attributes
    data: Ember.A([['Date', '99%', '66%', '33%']]),
    // Computed
    stats: Ember.computed('data', function () {
      var data = this.get('data'),
          totalData = 0,
          averageData = 0;

      if (data && data.length > 1) {

        data.forEach(function (row, index) {
          if (index > 0) {
            totalData = totalData + row[1];
          }
        });

        averageData = totalData / (data.length - 1);
      }

      return {
        totalData: Math.round(totalData),
        averageData: Math.round(averageData)
      };
    }),
    options: Ember.computed(function () {
      var bColor = '#FAFAFA',
          color = '#212121',
          lColor = '#424242';

      return {
        animation: {
          duration: 2,
          startup: true,
          easing: 'inAndOut'
        },
        annotations: {
          alwaysOutside: false
        },
        backgroundColor: bColor,
        colors: ['#EA80FC', '#E040FB', '#D500F9'],
        chartArea: {
          left: 50, //48,
          top: 12.5, //32,
          right: 50, //96,
          bottom: 50 //48,
        },
        height: 450,
        fontName: 'Lato',
        titleTextStyle: {
          color: color
        },
        legend: {
          position: 'none'
        },
        isStacked: true,
        areaOpacity: 0.6,
        hAxis: {
          baselineColor: lColor,
          gridlines: {
            color: lColor,
            count: 4
          },
          slantedText: false,
          showTextEvery: 7,
          title: 'Date',
          textStyle: {
            color: color
          },
          titleTextStyle: {
            color: color
          }
        },
        vAxis: {
          baselineColor: '#E0E0E0',
          gridlines: {
            color: '#E0E0E0',
            count: 5
          },
          minValue: 0,
          viewWindow: {
            min: 0
          },
          textStyle: {
            color: color
          },
          titleTextStyle: {
            color: color
          }
        }
      };
    })
  });
});