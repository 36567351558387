define('distorted-portal/components/uxs-datalist/multiline-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Services
    toast: Ember.inject.service(),
    // Attributes
    tagName: '',
    // Actions
    actions: {
      clipboardSuccess: function clipboardSuccess() /*audioGroup*/{
        Ember.get(this, 'toast').info('Added to Clipboard');
      },
      clipboardError: function clipboardError() /*audioGroup*/{
        Ember.get(this, 'toast').info('Error Accessing Clipboard');
      }
    }
  }).reopenClass({
    positionalParams: ['label', 'value']
  });
});