define('distorted-portal/controllers/internal/user/edit', ['exports', 'distorted-portal/utils/errors-to-json'], function (exports, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    analytics: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Controllers
    userController: Ember.inject.controller('internal.user'),
    // Attributes
    pageTitle: "Update User",
    // Actions
    actions: {
      submit: function submit(model) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('user.edit.save');

        model.save().then(function () {
          toast.info('User Updated');
          analytics.trackEvent('user.edit.success');
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('user.edit.error');
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('user.edit.invalid', errors);
      }
    }
  });
});