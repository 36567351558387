define('distorted-portal/serializers/audio-group', ['exports', 'ember-data', 'distorted-portal/mixins/serializers/embedded-jsonapi-records', 'ember-inflector'], function (exports, _emberData, _embeddedJsonapiRecords, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, _embeddedJsonapiRecords.default, {
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    },


    attrs: {
      audioGroupFiles: {
        serialize: 'records',
        deserialize: 'records'
      }
    }
  });
});