define('distorted-portal/routes/internal/episode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Attributes
    queryParams: {
      podcastId: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) {
      return this.store.findRecord('episode', params.episode_id, {
        include: 'category,podcast.client,language'
      });
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('podcastId', null);
      }
    }
  });
});