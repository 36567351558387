define('distorted-portal/routes/internal/episodes/new', ['exports', 'distorted-portal/mixins/routes/rollbackable'], function (exports, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackable.default, {
    // Attributes
    queryParams: {
      podcastId: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) /*transition*/{
      var _this = this;

      var podcast = this.store.peekRecord('podcast', params.podcastId);

      // In most cases the podcast will already exist in the ember data
      // cache. From a cold start however we will need to load from api
      if (!podcast) {
        return this.store.findRecord('podcast', params.podcastId).then(function (podcast) {
          return _this.store.createRecord('episode', {
            summary: Ember.get(podcast, 'summary')
          });
        });
      }

      return this.store.createRecord('episode', {
        summary: Ember.get(podcast, 'summary')
      });
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('podcastId', null);
      }
    }
  });
});