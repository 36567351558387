define('distorted-portal/components/syndication-list', ['exports', 'distorted-portal/utils/const/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Services
    session: Ember.inject.service(),
    // Attributes
    actionRoute: null,
    actionIcon: 'keyboard_arrow_right',
    tagName: '',
    // Computed
    isProgrammeList: Ember.computed.bool('programme'),
    isSyndicatorList: Ember.computed.bool('syndicator'),
    hasAction: Ember.computed.bool('actionRoute'),
    user: Ember.computed.alias('session.currentUser'),
    isAdmin: Ember.computed.equal('user.role', _user.ADMIN_ROLE),
    isClient: Ember.computed.equal('user.role', _user.CLIENT_ROLE),
    isSyndicator: Ember.computed.equal('user.role', _user.SYNDICATOR_ROLE),
    // Actions
    actions: {
      select: function select(syndication) {
        var action = Ember.get(this, 'onSelect');
        if (action) {
          return action(syndication);
        }
      },
      closeModal: function closeModal() {
        Ember.set(this, 'selectedSyndication', null);
      },
      openModal: function openModal(syndication) {
        Ember.set(this, 'selectedSyndication', syndication);
      },
      remove: function remove(syndication) {
        var action = Ember.get(this, 'onRemove');
        if (action) {
          return action(syndication);
        }
      }
    }
  });
});