define('distorted-portal/utils/const/client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Name',
    value: 'name'
  }, {
    label: 'Country',
    value: 'country_code'
  }];
  var SERVICE_OPTIONS = exports.SERVICE_OPTIONS = ['all', 'podcast', 'radio'];
  var ACTIONS = exports.ACTIONS = [{
    title: 'Dashboard',
    route: 'internal.client.dashboard',
    name: 'dashboard',
    ability: 'read client'
  }, {
    title: 'View Client',
    route: 'internal.client.index',
    name: 'view',
    ability: 'read client'
  }, {
    title: 'Edit Client',
    route: 'internal.client.edit',
    name: 'edit',
    ability: 'update client'
  }, {
    title: 'Client Podcasts',
    route: 'internal.client.podcasts',
    name: 'podcasts',
    ability: 'read client'
  }, {
    title: 'Client Programmes',
    route: 'internal.client.programmes',
    name: 'programmes',
    ability: 'read client'
  }, {
    title: 'Client Users',
    route: 'internal.client.users',
    name: 'users',
    ability: 'read client'
  }, {
    title: 'Archive Client',
    route: 'internal.client.archive',
    name: 'archive',
    ability: 'archive client'
  }];
});