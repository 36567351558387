define('distorted-portal/components/podcast-detail-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    icon: 'arrow_back',
    text: 'back',
    // Computed
    hasClient: Ember.computed.bool('clientId'),
    hasParent: Ember.computed.alias('hasClient'),
    parentId: Ember.computed.alias('clientId'),
    parentRoute: Ember.computed('hasClient', function () {
      return Ember.get(this, 'hasClient') ? 'internal.client.podcasts' : 'internal.podcasts';
    })
  });
});