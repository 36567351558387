define('distorted-portal/components/uxs-icon-link', ['exports', 'ember-ux-sauce/components/uxs-icon-link'], function (exports, _uxsIconLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsIconLink.default;
    }
  });
});