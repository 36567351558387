define('distorted-portal/models/audio-group', ['exports', 'ember-data', 'distorted-portal/mixins/validations/audio-group'], function (exports, _emberData, _audioGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_audioGroup.default, {
    // Attributes
    name: attr('string'),
    zipFile: attr('string'),
    // Relationships
    show: belongsTo(),
    audioGroupFiles: hasMany()
  });
});