define('distorted-portal/routes/internal/podcasts/trash', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable', 'distorted-portal/utils/const/user'], function (exports, _paginatable, _searchable, _sortable, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params),
          currentUser = Ember.get(this, 'session.currentUser');

      params.page = page;
      params.order = sort;
      params.include = 'client';
      params.deleted = true; // Request deleted records only

      if (Ember.get(currentUser, 'role') === _user.CLIENT_ROLE) {
        params['client_id'] = Ember.get(currentUser, 'client.id');
      }

      return this.store.query('podcast', params);
    }
  });
});