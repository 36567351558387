define('distorted-portal/components/user-filter', ['exports', 'distorted-portal/utils/const/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    roleOptions: _user.ROLE_OPTIONS,
    tagName: '',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'filterProps', ['role']);
    },

    // Actions
    actions: {
      filter: function filter() {
        Ember.set(this, 'showFilter', false);
      },
      reset: function reset() {
        var _this = this;

        // reset all filter properties
        Ember.get(this, 'filterProps').every(function (prop) {
          Ember.set(_this, prop, null);
        });
      }
    }
  });
});