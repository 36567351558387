define("distorted-portal/models/episode", ["exports", "ember-data", "distorted-portal/mixins/validations/episode", "moment"], function (exports, _emberData, _episode, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_episode.default, {
    // Attributes
    audioFileName: attr("string"),
    audioFileSize: attr("string"),
    audioFileMime: attr("string"),
    audioTrackLength: attr("number"),
    author: attr("string"),
    copyright: attr("string"),
    deletedAt: attr("date"),
    downloadedAt: attr("date"),
    downloadedById: attr("number"),
    image: attr("string"),
    isExplicit: attr("boolean"),
    itunesOwnerName: attr("string", {
      defaultValue: "This Is Distorted"
    }),
    keywords: attr("string"),
    releaseOn: attr("date"),
    status: attr("string"),
    subTitle: attr("string"),
    summary: attr("string"),
    title: attr("string"),
    itunesTitle: _emberData.default.attr("string"),
    link: _emberData.default.attr("string"),
    guid: _emberData.default.attr("string"),
    tracklist: attr("string"),
    episodeType: _emberData.default.attr("string"),
    season: _emberData.default.attr("number"),
    episode: _emberData.default.attr("number"),
    // Relationships
    podcast: belongsTo("podcast"),
    category: belongsTo("category"),
    language: belongsTo("language"),
    // Computed
    isPublished: Ember.computed.equal("status", "published"),
    computedStatus: Ember.computed("status", "releaseOn", "isPublished", function () {
      var status = Ember.get(this, "status"),
          releaseOn = Ember.get(this, "releaseOn"),
          now = (0, _moment.default)();

      if (Ember.get(this, "isPublished")) {
        if (now.isBefore(releaseOn)) {
          return "scheduled";
        }
      }
      return status;
    }),
    statusStyle: Ember.computed("computedStatus", function () {
      switch (Ember.get(this, "computedStatus")) {
        case "published":
          return "primary";
        case "draft":
          return "accent";
        case "scheduled":
          return "warning";
        default:
          return "grey";
      }
    }),
    avatar: Ember.computed("image", function () {
      var image = this.get("image");
      if (image) {
        return image;
      }
      return "http://placehold.it/600x600/A7FFEB/424242?text=image";
    })
  });
});