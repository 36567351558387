define("distorted-portal/helpers/boolean-to-human", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.booleanToHuman = booleanToHuman;
  function booleanToHuman(params /*, hash*/) {
    var value = params[0];
    if (value) {
      return "yes";
    }
    return "no";
  }

  exports.default = Ember.Helper.helper(booleanToHuman);
});