define('distorted-portal/adapters/application', ['exports', 'ember-data', 'distorted-portal/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.rootApiURL,
    namespace: _environment.default.apiNamespace
  });
});