define('distorted-portal/utils/formatted-date-range', ['exports', 'ember-ux-date-sauce/utils/formatted-date-range'], function (exports, _formattedDateRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formattedDateRange.default;
    }
  });
});