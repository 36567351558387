define('distorted-portal/components/syndicator-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    defaultRoute: 'internal.syndicator.index',
    // Computed
    sortByCountry: Ember.computed.equal('sortBy', 'country_code'),
    sortByPlatform: Ember.computed.equal('sortBy', 'platform'),
    sortByAudience: Ember.computed.equal('sortBy', 'audience')
  });
});