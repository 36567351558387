define('distorted-portal/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    // Attributes
    name: attr('string'),
    // Relationships
    podcasts: hasMany(),
    programmes: hasMany(),
    shows: hasMany()
    // episodes: hasMany('episode'),
  });
});