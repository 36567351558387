define('distorted-portal/app', ['exports', 'distorted-portal/resolver', 'ember-load-initializers', 'distorted-portal/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (!jqXHR.crossDomain) {
      // check this is an internal api request
      if (options.url.includes(_environment.default.rootApiURL)) {
        options.contentType = 'application/vnd.api+json';
        options.beforeSend = function (jqXHR) {
          jqXHR.setRequestHeader('Accept', 'application/vnd.api+json');
          jqXHR.setRequestHeader('Content-Type', 'application/vnd.api+json');
        };
        jqXHR.setRequestHeader('Authorization', localStorage.getItem('auth_token'));
      }
      /*else {
           console.log(options.url);
         }*/
    }

    if (options.dataType == 'text') {
      options.contentType = 'text/csv';
      options.beforeSend = function (jqXHR) {
        jqXHR.setRequestHeader('Accept', 'text/csv; charset=utf-8');
        jqXHR.setRequestHeader('Content-Type', 'text/csv; charset=utf-8');
      };
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});