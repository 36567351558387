define('distorted-portal/components/show-detail-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    icon: 'arrow_back',
    text: 'back',
    // Computed
    hasProgramme: Ember.computed.bool('programmeId'),
    hasSyndication: Ember.computed.bool('syndicationId'),
    hasParent: Ember.computed.or('hasProgramme', 'hasSyndication'),
    parentId: Ember.computed('programmeId', 'syndicationId', function () {
      var programmeId = Ember.get(this, 'programmeId'),
          syndicationId = Ember.get(this, 'syndicationId');
      return programmeId ? programmeId : syndicationId;
    }),
    parentRoute: Ember.computed('hasProgramme', function () {
      if (Ember.get(this, 'hasProgramme')) {
        return 'internal.programme.shows';
      }
      return 'internal.syndication.shows';
    }),
    baseRoute: Ember.computed('hasLatest', function () {
      if (Ember.get(this, 'hasLatest')) {
        return 'internal.latest';
      }
      return 'internal.shows';
    })
  });
});