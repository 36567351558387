define('distorted-portal/models/show', ['exports', 'ember-data', 'distorted-portal/mixins/validations/show', 'moment'], function (exports, _emberData, _show, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_show.default, {
    // Attributes
    author: attr('string'),
    copyright: attr('string'),
    description: attr('string'),
    downloadedAt: attr('date'),
    downloadedById: attr('number'),
    image: attr('string'),
    isExplicit: attr('boolean'),
    keywords: attr('string'),
    releaseOn: attr('date'),
    status: attr('string'),
    subTitle: attr('string'),
    summary: attr('string'),
    title: attr('string'),
    tracklist: attr('string'),
    // Relationships
    programme: belongsTo('programme'),
    category: belongsTo(),
    language: belongsTo(),
    audioGroups: hasMany(),
    // Computed
    isPublished: Ember.computed.equal('status', 'published'),
    isNew: Ember.computed('downloadedAt', function () {
      var twoWeeksAgo = (0, _moment.default)().subtract("weeks", 2);
      return !(Ember.get(this, 'downloadedAt') || (0, _moment.default)(Ember.get(this, 'releaseOn')).isBefore(twoWeeksAgo));
    }),
    computedStatus: Ember.computed('status', 'releaseOn', 'isPublished', function () {
      var status = Ember.get(this, 'status'),
          releaseOn = Ember.get(this, 'releaseOn'),
          now = (0, _moment.default)();

      if (Ember.get(this, 'isPublished')) {
        if (now.isBefore(releaseOn)) {
          return 'scheduled';
        }
      }
      return status;
    }),
    statusStyle: Ember.computed('computedStatus', function () {
      switch (Ember.get(this, 'computedStatus')) {
        case 'published':
          return 'primary';
        case 'draft':
          return 'accent';
        case 'scheduled':
          return 'warning';
        default:
          return 'grey';
      }
    }),
    avatar: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return image;
      }
      return "http://placehold.it/600x600/A7FFEB/424242?text=image";
    })
  });
});