define('distorted-portal/serializers/recover-password', ['exports', 'ember-data', 'distorted-portal/mixins/serializers/embedded-jsonapi-records'], function (exports, _emberData, _embeddedJsonapiRecords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, _embeddedJsonapiRecords.default, {
    payloadKeyFromModelName: function payloadKeyFromModelName() /*modelName*/{
      return 'user';
    }
  });
});