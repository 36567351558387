define('distorted-portal/controllers/internal/episodes/new', ['exports', 'distorted-portal/mixins/controllers/imageuploadable', 'distorted-portal/mixins/controllers/audiouploadable', 'distorted-portal/utils/errors-to-json'], function (exports, _imageuploadable, _audiouploadable, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_audiouploadable.default, _imageuploadable.default, {
    // Service
    analytics: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Attributes
    pageTitle: "New Episode",
    queryParams: ['podcastId'],
    podcastId: null,
    // Methods
    redirectSuccess: function redirectSuccess() {
      var podcastId = Ember.get(this, 'podcastId');
      if (podcastId) {
        return this.transitionToRoute('internal.podcast.episodes', podcastId);
      } else {
        return this.transitionToRoute('internal.episodes');
      }
    },

    // Actions
    actions: {
      submit: function submit(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('episode.new.save');

        model.save().then(function () {
          toast.info('Episode Created');
          analytics.trackEvent('episode.new.success');
          _this.redirectSuccess();
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('episode.new.error');
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('episode.new.invalid', errors);
      }
    }
  });
});