define('distorted-portal/macros/group-by-path', ['exports', 'ember-cli-group-by/macros/group-by-path'], function (exports, _groupByPath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _groupByPath.default;
    }
  });
});