define('distorted-portal/mixins/serializers/embedded-jsonapi-records', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // add json-api compliant serialization type
    hasSerializeIdsAndTypeOption: function hasSerializeIdsAndTypeOption(attr) {
      var option = this.attrsOption(attr);
      return option && option.serialize === 'ids-and-type';
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {

      var attr = relationship.key;

      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }

      var includeIds = this.hasSerializeIdsOption(attr);
      var includeIdsAndType = this.hasSerializeIdsAndTypeOption(attr);
      var includeRecords = this.hasSerializeRecordsOption(attr);
      if (includeIdsAndType) {
        var serializedKey = (0, _emberInflector.singularize)(this.keyForRelationship(attr, relationship.kind, 'serialize'));
        var files = snapshot.hasMany(attr, {
          ids: true
        }),
            serializedRecords = [];
        if (files) {
          serializedRecords = files.map(function (id) {
            return {
              id: id,
              type: (0, _emberInflector.singularize)(relationship.type),
              attributes: relationship.get('attributes')
            };
          });
        }

        if (!json['relationships']) {
          json['relationships'] = {};
        }
        json['relationships'][serializedKey] = {
          data: serializedRecords
        };
      } else if (includeIds) {
        var _serializedKey = this.keyForRelationship(attr, relationship.kind, 'serialize');
        json[_serializedKey] = snapshot.hasMany(attr, {
          ids: true
        });
      } else if (includeRecords) {

        //this._serializeEmbeddedHasMany(snapshot, json, relationship);

        var _serializedKey2 = (0, _emberInflector.singularize)(this.keyForRelationship(attr, relationship.kind, 'serialize')),
            _files = snapshot.hasMany(attr),
            _serializedRecords = [];

        if (_files) {
          _serializedRecords = _files.map(function (model) {
            return {
              id: model.id,
              type: (0, _emberInflector.singularize)(relationship.type),
              attributes: model.attributes()
            };
          });
        }

        if (!json['relationships']) {
          json['relationships'] = {};
        }
        json['relationships'][_serializedKey2] = {
          data: _serializedRecords
        };
      }
    }

  });
});