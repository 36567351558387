define('distorted-portal/components/podcast-grid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    defaultRoute: 'internal.podcast.index',
    // Computed
    queryParams: Ember.computed('client.id', function () {

      var params = {},
          clientId = Ember.get(this, 'client.id');

      if (clientId) {
        params.clientId = clientId;
      }

      return params;
    })
  });
});