define('distorted-portal/mirage/factories/episode', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    audioFileName: function audioFileName() {
      return 'filename';
      //return faker.system.fileName();
    },
    audioFileMime: function audioFileMime() {
      return 'audio/mp3';
      //return faker.system.mimeType();
    },
    audioFileSize: function audioFileSize() {
      return 123456;
      //return faker.random.number();
    },
    audioTrackLength: function audioTrackLength() {
      return '20:12';
    },
    title: function title() {
      return (_emberCliMirage.faker.hacker.verb() + ' ' + _emberCliMirage.faker.hacker.noun()).capitalize();
    },
    subTitle: function subTitle() {
      return _emberCliMirage.faker.hacker.phrase();
    },
    image: function image() {
      return _emberCliMirage.faker.image.imageUrl(320, 320, undefined, true);
    },
    author: function author() {
      return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
    },
    copyright: function copyright() {
      return _emberCliMirage.faker.company.companyName() + ' ' + _emberCliMirage.faker.date.past().getYear();
    },
    summary: function summary() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    tracklist: function tracklist() {
      return _emberCliMirage.faker.lorem.lines(6);
    },
    releaseOn: function releaseOn() {
      return _emberCliMirage.faker.date.recent();
    },
    status: function status() {
      return _emberCliMirage.faker.random.arrayElement(['published', 'draft']);
    },
    keywords: function keywords() {
      return _emberCliMirage.faker.lorem.words();
    },
    iTunesOwnerName: function iTunesOwnerName() {
      return 'Distorted';
    },
    isExplicit: function isExplicit() {
      return _emberCliMirage.faker.random.boolean();
    },
    deletedAt: function deletedAt() {
      return null;
    },

    // Traits
    withPodcast: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(show, server) {
        var podcasts = server.schema.podcasts.all().models,
            podcast = _emberCliMirage.faker.random.arrayElement(podcasts);
        show.update({
          podcast: podcast
        });
      }
    }),
    withCategory: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(show, server) {
        var categories = server.schema.categories.all().models,
            category = _emberCliMirage.faker.random.arrayElement(categories);
        show.update({
          category: category
        });
      }
    }),
    withLanguage: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(show, server) {
        var languages = server.schema.languages.all().models,
            language = _emberCliMirage.faker.random.arrayElement(languages);
        show.update({
          language: language
        });
      }
    })
  });
});