define('distorted-portal/mixins/controllers/sortable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    queryParams: ['sort'],
    sort: '',
    // Actions
    actions: {
      sortBy: function sortBy(attribute) {
        var sort = Ember.get(this, 'sort');
        if (sort === attribute) {
          attribute = '-' + sort;
        }
        this.transitionToRoute({
          queryParams: {
            sort: attribute
          }
        });
      }
    }
  });
});