define('distorted-portal/utils/const/programme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Title',
    value: 'title'
  }, {
    label: 'Author',
    value: 'author'
  }, {
    label: 'Surname',
    value: 'lastName'
  }, {
    label: 'Client',
    value: 'client.name'
  }];
  var ACTIONS = exports.ACTIONS = [{
    title: 'Dashboard',
    route: 'internal.programme.dashboard',
    name: 'dashboard',
    ability: 'read programme'
  }, {
    title: 'View Programme',
    route: 'internal.programme.index',
    name: 'view',
    ability: 'read programme'
  }, {
    title: 'Edit Programme',
    route: 'internal.programme.edit',
    name: 'edit',
    ability: 'update programme'
  }, {
    title: 'Programme Shows',
    route: 'internal.programme.shows',
    name: 'shows',
    ability: 'read programme'
  }, {
    title: 'Programme Syndications',
    route: 'internal.programme.syndications',
    name: 'syndications',
    ability: 'read programme'
  }, {
    title: 'Archive Programme',
    route: 'internal.programme.archive',
    name: 'archive',
    ability: 'archive programme'
  }];
});