define('distorted-portal/controllers/internal/syndicator/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: 'Archive Syndicator',
    // Actions
    actions: {
      archive: function archive(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('syndicator.archive.save');

        model.deleteRecord();
        model.save().then(function () {
          toast.info('Syndicator Archived');
          analytics.trackEvent('syndicator.archive.success');
          return _this.transitionToRoute('internal.syndicators');
        }, function () {
          analytics.trackEvent('syndicator.archive.error');
          return toast.info('Server Error');
        });
      },
      cancel: function cancel(model) {
        var analytics = Ember.get(this, 'analytics');
        analytics.trackEvent('syndicator.archive.cancel');
        return this.transitionToRoute('internal.syndicator.index', model.id);
      }
    }
  });
});