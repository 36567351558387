define('distorted-portal/controllers/internal/user/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controllers
    userController: Ember.inject.controller('internal.user'),
    // Attributes
    pageTitle: "View User"
  });
});