define('distorted-portal/models/podcast', ['exports', 'ember-data', 'distorted-portal/mixins/validations/podcast', 'moment'], function (exports, _emberData, _podcast, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_podcast.default, {
    // Attributes
    author: attr('string', {
      defaultValue: 'This Is Distorted'
    }),
    copyright: attr('string', {
      defaultValue: function defaultValue() {
        return 'This Is Distorted ' + (0, _moment.default)().year();
      }
    }),
    description: attr('string'),
    image: attr('string'),
    itunesOwnerName: attr('string', {
      defaultValue: 'This Is Distorted'
    }),
    itunesOwnerEmail: attr('string', {
      defaultValue: 'info@thisisdistorted.com'
    }),
    isExplicit: attr('boolean'),
    keywords: attr('string'),
    subTitle: attr('string'),
    summary: attr('string'),
    title: attr('string'),
    itunesTitle: _emberData.default.attr('string'),
    itunesType: _emberData.default.attr('string'),
    totalEpisodes: attr('number', {
      defaultValue: 0
    }),
    xmlFeed: attr('string'),
    // Relationships
    client: belongsTo(),
    category: belongsTo(),
    language: belongsTo(),
    episodes: hasMany('episode', {
      inverse: 'podcast'
    }),
    previousEpisode: belongsTo('episode', {
      inverse: null
    }),
    // Computed
    avatar: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return image;
      }
      return "http://placehold.it/600x600/A7FFEB/424242?text=image";
    }),
    showCount: Ember.computed(function () {
      var n = Math.floor(Math.random() * 10 + 1);
      if (n === 1) {
        return n + ' show';
      } else {
        return n + ' shows';
      }
    }),
    lastEpisodeTitle: Ember.computed.alias('podcast.title')
  });
});