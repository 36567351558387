define('distorted-portal/transitions/custom-to-right', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (opts) {
    var _this = this;

    return this.lookup('to-right').call(this, opts).then(function () {
      _this.newElement.css('transform', '');
    });
  };
});