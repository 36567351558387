define('distorted-portal/components/area-chart', ['exports', 'ember-google-charts/components/area-chart'], function (exports, _areaChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _areaChart.default;
    }
  });
});