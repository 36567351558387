define('distorted-portal/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    // Transition for filter forms
    this.transition(this.hasClass('liquid-from-right'), this.toValue(true), this.use('customToLeft'), this.reverse('customToRight'));

    /**
     * AUTH TRANSITIONS
     */
    this.transition(this.fromRoute('external'), this.toRoute('internal'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.fromRoute('external.login'), this.toRoute('external.recover-password'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.fromRoute('external.login'), this.toRoute('external.reset-password'), this.use('customToUp'), this.reverse('customToDown'));
    this.transition(this.toRoute('internal.logout'), this.use('fade'), this.reverse('fade'));

    /**
     * DASHBOARD TRANSITIONS
     */
    this.transition(this.fromRoute('internal.dashboard'), this.toRoute(['internal.users', 'internal.account']), this.use('customToUp'), this.reverse('customToDown'));

    /**
     * ACCOUNT TRANSITIONS
     */
    this.transition(this.fromRoute('internal.account.index'), this.toRoute('internal.account.password'), this.use('fade'), this.reverse('fade'));

    /**
     * USER TRANSITIONS
     */
    this.transition(this.fromRoute('internal.users.index'), this.toRoute(['internal.users.new', 'internal.users.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.users'), this.toRoute('internal.user'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.user.index', 'internal.user.edit', 'internal.user.password', 'internal.user.archive']), this.toRoute(['internal.user.index', 'internal.user.edit', 'internal.user.password', 'internal.user.archive']), this.use('fade'), this.reverse('fade'));

    // Relationship transitions
    this.transition(this.fromRoute(['internal.client', 'internal.syndicator']), this.toRoute(['internal.user', 'internal.users']), this.use('customToLeft'), this.reverse('customToRight'));

    /**
     * CLIENT TRANSITIONS
     */
    this.transition(this.fromRoute('internal.clients.index'), this.toRoute(['internal.clients.new', 'internal.clients.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.clients'), this.toRoute('internal.client'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.client.dashboard', 'internal.client.index', 'internal.client.edit', 'internal.client.programmes', 'internal.client.podcasts', 'internal.client.users', 'internal.client.archive']), this.toRoute(['internal.client.dashboard', 'internal.client.index', 'internal.client.edit', 'internal.client.programmes', 'internal.client.podcasts', 'internal.client.users', 'internal.client.archive']), this.use('fade'), this.reverse('fade'));

    /**
     * SYNDICATORS TRANSITIONS
     */
    this.transition(this.fromRoute('internal.syndicators.index'), this.toRoute(['internal.syndicators.new', 'internal.syndicators.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.syndicators'), this.toRoute('internal.syndicator'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.syndicator.index', 'internal.syndicator.edit', 'internal.syndicator.syndications', 'internal.syndicator.users', 'internal.syndicator.archive']), this.toRoute(['internal.syndicator.index', 'internal.syndicator.edit', 'internal.syndicator.syndications', 'internal.syndicator.users', 'internal.syndicator.archive']), this.use('fade'), this.reverse('fade'));

    /**
     * PODCAST TRANSITIONS
     */
    this.transition(this.fromRoute('internal.podcasts.index'), this.toRoute(['internal.podcasts.new', 'internal.podcasts.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.podcasts'), this.toRoute('internal.podcast'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.podcast.dashboard', 'internal.podcast.index', 'internal.podcast.edit', 'internal.podcast.episodes', 'internal.podcast.archive']), this.toRoute(['internal.podcast.dashboard', 'internal.podcast.index', 'internal.podcast.edit', 'internal.podcast.episodes', 'internal.podcast.archive']), this.use('fade'), this.reverse('fade'));
    // Relationship transitions
    this.transition(this.fromRoute(['internal.client']), this.toRoute(['internal.podcast', 'internal.podcasts']), this.use('customToLeft'), this.reverse('customToRight'));
    this.transition(this.fromRoute(['internal.podcast']), this.toRoute(['internal.episodes', 'internal.episode']), this.use('customToLeft'), this.reverse('customToRight'));

    /**
     * PROGRAMME TRANSITIONS
     */
    this.transition(this.fromRoute('internal.programmes.index'), this.toRoute(['internal.programmes.new', 'internal.programmes.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.programmes'), this.toRoute('internal.programme'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.programme.dashboard', 'internal.programme.index', 'internal.programme.edit', 'internal.programme.shows', 'internal.programme.syndications', 'internal.programme.archive']), this.toRoute(['internal.programme.dashboard', 'internal.programme.index', 'internal.programme.edit', 'internal.programme.shows', 'internal.programme.syndications', 'internal.programme.archive']), this.use('fade'), this.reverse('fade'));
    // Relationship transitions
    this.transition(this.fromRoute(['internal.client']), this.toRoute(['internal.programme', 'internal.programmes']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.programme']), this.toRoute(['internal.shows', 'internal.show']), this.use('customToLeft'), this.reverse('customToRight'));

    /**
     * EPISODE TRANSITIONS
     */
    this.transition(this.fromRoute('internal.episodes.index'), this.toRoute(['internal.episodes.new', 'internal.episodes.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.episodes'), this.toRoute('internal.episode'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.episode.dashboard', 'internal.episode.index', 'internal.episode.edit', 'internal.episode.archive']), this.toRoute(['internal.episode.dashboard', 'internal.episode.index', 'internal.episode.edit', 'internal.episode.archive']), this.use('fade'), this.reverse('fade'));

    /**
     * SHOW TRANSITIONS
     */
    this.transition(this.fromRoute('internal.shows.index'), this.toRoute(['internal.shows.new', 'internal.shows.trash']), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute('internal.shows'), this.toRoute('internal.show'), this.use('customToLeft'), this.reverse('customToRight'));

    this.transition(this.fromRoute(['internal.show.dashboard', 'internal.show.index', 'internal.show.edit', 'internal.show.files', 'internal.show.archive']), this.toRoute(['internal.show.dashboard', 'internal.show.index', 'internal.show.edit', 'internal.show.files', 'internal.show.archive']), this.use('fade'), this.reverse('fade'));
  };
});