define('distorted-portal/routes/internal/syndication/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    // Route just here to redirect links from
    // old portals email links
    beforeModel: function beforeModel(transition) {
      var showId = transition.params['internal.syndication.show'].show_id;
      this.transitionTo('internal.show', showId);
    },
    model: function model() {
      return {};
    }
  });
});