define('distorted-portal/components/episode-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    defaultRoute: 'internal.episode.index',
    // Computed
    queryParams: Ember.computed('podcast.id', function () {

      var params = {},
          podcastId = Ember.get(this, 'podcast.id');

      if (podcastId) {
        params.podcastId = podcastId;
      }

      return params;
    })
  });
});