define('distorted-portal/utils/const/syndication', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ACTIONS = exports.ACTIONS = [{
    title: 'View Programme',
    route: 'internal.syndication.index',
    name: 'view',
    ability: 'read syndication'
  }, {
    title: 'Programme Shows',
    route: 'internal.syndication.shows',
    name: 'shows',
    ability: 'read syndication'
  }];
});