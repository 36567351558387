define('distorted-portal/models/syndicator-user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Attributes
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    contactNumber: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    lastLoginDate: (0, _attr.default)('date'),
    // Relationships
    client: (0, _relationships.belongsTo)('client'),
    syndicator: (0, _relationships.belongsTo)('syndicator'),
    // Computed
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName'),
          lastName = this.get('lastName');
      if (firstName) {
        return firstName + ' ' + lastName;
      }
    }),
    initials: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName'),
          lastName = this.get('lastName');
      if (firstName) {
        return firstName.charAt(0) + lastName.charAt(0);
      }
    }),
    avatarThumb: Ember.computed('firstName', 'lastName', function () {
      var initials = this.get('initials');
      return "http://placehold.it/100x100/A7FFEB/424242?text=" + initials;
    }),
    avatar: Ember.computed('firstName', 'lastName', function () {
      var initials = this.get('initials');
      return "http://placehold.it/600x600/A7FFEB/424242?text=" + initials;
    }),
    lastSeen: Ember.computed('lastLoginDate', function () {
      var date = this.get('lastLoginDate');
      if (date) {
        return (0, _moment.default)(date).fromNow();
      }
      return "never";
    }),
    isAdmin: Ember.computed('role', function () {
      return this.get('role') === 'admin';
    }),
    isClient: Ember.computed('role', function () {
      return this.get('role') === 'client';
    }),
    isSyndicator: Ember.computed('role', function () {
      return this.get('role') === 'syndicator';
    })
  });
});