define('distorted-portal/helpers/html-safe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlSafeHelper = htmlSafeHelper;
  function htmlSafeHelper(params /*, hash*/) {
    return Ember.String.htmlSafe(params.join(''));
  }

  exports.default = Ember.Helper.helper(htmlSafeHelper);
});