define('distorted-portal/utils/const/podcast', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Title',
    value: 'title'
  }, {
    label: 'Author',
    value: 'author'
  }, {
    label: 'Surname',
    value: 'lastName'
  }, {
    label: 'Client',
    value: 'client.name'
  }];
  var PODCAST_TYPES = exports.PODCAST_TYPES = [{
    groupName: "Arts",
    options: ["Books", "Design", "Fashion & Beauty", "Food", "Performing Arts", "Visual Arts"]
  }, {
    groupName: "Business",
    options: ["Careers", "Entrepreneurship", "Investing", "Management", "Marketing", "Non-Profit"]
  }, {
    groupName: "Comedy",
    options: ["Comedy Interviews", "Improv", "Stand - Up"]
  }, {
    groupName: "Education",
    options: ["Courses", "How To", "Language Learning", "Self - Improvement"]
  }, {
    groupName: "Fiction",
    options: ["Comedy Fiction", "Drama", "Science Fiction"]
  }, {
    groupName: "Government",
    options: ["Government"]
  }, {
    groupName: "History",
    options: ["History"]
  }, {
    groupName: "Health & Fitness",
    options: ["Alternative Health", "Fitness", "Medicine", "Mental Health", "Nutrition", "Sexuality"]
  }, {
    groupName: "Kids & Family",
    options: ["Education for Kids", "Parenting", "Pets & Animals", "Stories for Kids"]
  }, {
    groupName: "Leisure",
    options: ["Animation & Manga", "Automotive", "Aviation", "Crafts", "Games", "Hobbies", "Home & Garden", "Video Games"]
  }, {
    groupName: "Music",
    options: ["Music Commentary", "Music History", "Music Interviews"]
  }, {
    groupName: "News",
    options: ["Business News", "Daily News", "Entertainment News", "News Commentary", "Politics", "Sports News", "Tech News"]
  }, {
    groupName: "Religion & Spirituality",
    options: ["Buddhism", "Christianity", "Hinduism", "Islam", "Judaism", "Religion", "Spirituality"]
  }, {
    groupName: "Science",
    options: ["Astronomy", "Chemistry", "Earth Sciences", "Life Sciences", "Mathematics", "Natural Sciences", "Nature", "Physics", "Social Sciences"]
  }, {
    groupName: "Society & Culture",
    options: ["Documentary", "Personal Journals", "Philosophy", "Places & Travel", "Relationships"]
  }, {
    groupName: "Sports",
    options: ["Baseball", "Basketball", "Cricket", "Fantasy Sports", "Football", "Golf", "Hockey", "Rugby", "Running", "Soccer", "Swimming", "Tennis", "Volleyball", "Wilderness", "Wrestling"]
  }, {
    groupName: "Technology",
    options: ["Technology"]
  }, {
    groupName: "True Crime",
    options: ["True Crime"]
  }, {
    groupName: "TV & Film",
    options: ["After Shows", "Film History", "Film Interviews", "Film Reviews", "TV Reviews"]
  }];

  var ACTIONS = exports.ACTIONS = [{
    title: 'Dashboard',
    route: 'internal.podcast.dashboard',
    name: 'dashboard',
    ability: 'read podcast'
  }, {
    title: 'View Podcast',
    route: 'internal.podcast.index',
    name: 'view',
    ability: 'read podcast'
  }, {
    title: 'Edit Podcast',
    route: 'internal.podcast.edit',
    name: 'edit',
    ability: 'update podcast'
  }, {
    title: 'Podcast Episodes',
    route: 'internal.podcast.episodes',
    name: 'episodes',
    ability: 'read podcast'
  }, {
    title: 'Archive Podcast',
    route: 'internal.podcast.archive',
    name: 'archive',
    ability: 'archive podcast'
  }];
});