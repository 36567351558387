define('distorted-portal/models/programme', ['exports', 'ember-data', 'distorted-portal/mixins/validations/programme', 'moment'], function (exports, _emberData, _programme, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_programme.default, {
    // Attributes
    author: attr('string', {
      defaultValue: 'This Is Distorted'
    }),
    copyright: attr('string', {
      defaultValue: function defaultValue() {
        return 'This Is Distorted ' + (0, _moment.default)().year();
      }
    }),
    description: attr('string'),
    image: attr('string'),
    summary: attr('string'),
    subTitle: attr('string'),
    title: attr('string'),
    totalShows: attr('number'),
    totalSyndicators: attr('number'),
    // Relationships
    client: belongsTo('client'),
    category: belongsTo('category'),
    language: belongsTo('language'),
    syndications: hasMany('programme-syndicator'),
    shows: hasMany('show', {
      inverse: 'programme'
    }),
    previousShow: belongsTo('show', {
      inverse: null
    }),
    // Computed
    avatar: Ember.computed('image', function () {
      var image = this.get('image');
      if (image) {
        return image;
      }
      return "http://placehold.it/600x600/A7FFEB/424242?text=image";
    })
  });
});