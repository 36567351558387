define('distorted-portal/controllers/internal/podcast/episodes', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/const/episode'], function (exports, _paginatable, _searchable, _sortable, _episode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controller
    podcastController: Ember.inject.controller('internal.podcast'),
    // Attributes
    pageTitle: "Podcast Episodes",
    sort: 'title',
    sortOptions: _episode.SORT_OPTIONS
  });
});