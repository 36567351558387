define('distorted-portal/mixins/controllers/dashboardable', ['exports', 'moment', 'ember-ux-date-sauce/utils/format-to-range'], function (exports, _moment, _formatToRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    isShowingModal: false,
    queryParams: ['range', 'country', 'type'],
    range: null,
    type: 'podcast',

    // Computed
    showPodcastCharts: Ember.computed.equal('type', 'podcast'),
    showProgrammeCharts: Ember.computed.equal('type', 'programme'),
    startDate: Ember.computed('range', function () {
      var range = (0, _formatToRange.default)(Ember.get(this, 'range'));
      if (range) {
        return range[0].format('YYYY-MM-DD');
      }
    }),
    endDate: Ember.computed('range', function () {
      var range = (0, _formatToRange.default)(Ember.get(this, 'range'));
      if (range) {
        return range[1].format('YYYY-MM-DD');
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      var start = (0, _moment.default)().subtract(1, 'month').format('DD/MM/YY'),
          end = (0, _moment.default)().format('DD/MM/YY');
      Ember.set(this, 'range', start + '..' + end);
    },

    // Actions
    actions: {
      showFilterModal: function showFilterModal() {
        Ember.set(this, 'isShowingModal', true);
      },
      closeFilterModal: function closeFilterModal() {
        Ember.set(this, 'isShowingModal', false);
      },
      setDateRange: function setDateRange(range, format /*, ref*/) {
        Ember.set(this, 'range', format);
      }
    }
  });
});