define('distorted-portal/controllers/external/reset-password', ['exports', 'distorted-portal/config/environment', 'distorted-portal/utils/get-api-url'], function (exports, _environment, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    toast: Ember.inject.service(),
    // Attributes
    name: _environment.default.APP.name,
    // Actions
    actions: {
      reset: function reset(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            data = JSON.stringify(model.serialize()),
            url = (0, _getApiUrl.default)('/reset_password');

        Ember.$.ajax({
          type: 'PUT',
          url: url,
          accept: 'application/vnd.api+json',
          contentType: 'application/vnd.api+json',
          data: data,
          success: function success() {
            toast.info('Password updated, please login');
            _this.transitionToRoute('external.login');
          },
          error: function error(jqXHR, textStatus) {
            if (textStatus === 0) {
              toast.info('Failed to connect to server, please try again.');
            } else {
              toast.info('Token invalid. Please check the url in your email.');
            }
          }
        });
      },
      invalid: function invalid() {
        var toast = Ember.get(this, 'toast');
        toast.info('Please correct form errors');
      }
    }
  });
});