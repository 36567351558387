define('distorted-portal/models/client', ['exports', 'ember-data', 'distorted-portal/mixins/validations/client'], function (exports, _emberData, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_client.default, {
    // Service
    countryService: Ember.inject.service(),
    // Attributes
    countryCode: attr('string'),
    deletedAt: attr('date'),
    description: attr('string'),
    facebook: attr('string'),
    image: attr('string'),
    name: attr('string'),
    service: attr('string'),
    twitter: attr('string'),
    website: attr('string'),
    // Relationships
    users: hasMany(),
    programmes: hasMany(),
    podcasts: hasMany(),
    // Computed
    countryName: Ember.computed('countryCode', function () {
      return Ember.get(this, 'countryService').nameForCode(Ember.get(this, 'countryCode'));
    })
  });
});