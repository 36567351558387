define('distorted-portal/mixins/validations/client', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    countryCode: [(0, _emberCpValidations.validator)('presence', true)],
    description: [(0, _emberCpValidations.validator)('presence', true)],
    facebook: [],
    image: [],
    name: [(0, _emberCpValidations.validator)('presence', true)],
    service: [(0, _emberCpValidations.validator)('presence', true)],
    twitter: [],
    website: []
  });
});