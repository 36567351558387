define('distorted-portal/controllers/internal/programme/syndications', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/errors-to-json', 'distorted-portal/utils/get-api-url'], function (exports, _paginatable, _searchable, _sortable, _errorsToJson, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controller
    programmeController: Ember.inject.controller('internal.programme'),
    // Services
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: 'Programme Syndications',
    // Actions
    actions: {
      openNewSyndicationModel: function openNewSyndicationModel(programme) {
        var newSyndication = Ember.get(this, 'store').createRecord('programme-syndicator', {
          programme: programme
        });
        Ember.set(this, 'newSyndication', newSyndication);
        Ember.set(this, 'isShowingModal', true);
      },
      closeNewSyndicationModel: function closeNewSyndicationModel() {
        Ember.set(this, 'isShowingModal', false);
      },
      submitNewSyndication: function submitNewSyndication(newSyndication) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('programme.new-syndication.save');

        newSyndication.save().then(function () {
          toast.info('Syndication Added');
          analytics.trackEvent('programme.new-syndication.success');
          Ember.set(_this, 'isShowingModal', false);
          _this.get('target').send('refresh');
        }, function (response) {
          var errors = Ember.get(response, 'errors');

          if (errors) {
            // If we have validation errors display the first one
            analytics.trackEvent('programme.new-syndication.error', (0, _errorsToJson.default)(errors, true));
            toast.info(Ember.get(response, 'errors.firstObject.detail'));
          } else {
            // else, general error message
            analytics.trackEvent('programme.new-syndication.error');
            toast.info('Server Error');
          }
          Ember.set(_this, 'isShowingModal', false);
        });
      },
      archiveSyndication: function archiveSyndication(syndication) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('programme.archive-syndication.save');

        syndication.deleteRecord();
        syndication.save().then(function () {
          toast.info('Syndication Archived');
          analytics.trackEvent('programme.archive-syndication.success');
        }, function (response) {
          var errors = Ember.get(response, 'errors');

          if (errors) {
            // If we have validation errors display the first one
            analytics.trackEvent('programme.archive-syndication.error', (0, _errorsToJson.default)(errors, true));
            toast.info(Ember.get(response, 'errors.firstObject.detail'));
          } else {
            // else, general error message
            analytics.trackEvent('programme.archive-syndication.error');
            toast.info('Server Error');
          }
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('syndicator.programmes.invalid', errors);
      },
      goToSyndicator: function goToSyndicator(syndication) {
        return this.transitionToRoute('internal.syndicator.index', Ember.get(syndication, 'syndicator.id'));
      },
      downloadCSV: function downloadCSV(programmeId) {
        var url = (0, _getApiUrl.default)('/programme-syndicators-export?programme_id=' + programmeId);
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'text',
          success: function success(data, textStatus, jqxhr) {
            var file = new Blob([data], {
              type: jqxhr.getResponseHeader('Content-Type')
            });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = 'programme-syndications-' + programmeId;
            link.click();
          },
          error: function error(jqxhr, textStatus, errorThrown) {
            console.log(textStatus, errorThrown);
          }
        });
      }
    }
  });
});