define("distorted-portal/routes/internal/dashboard", ["exports", "distorted-portal/mixins/routes/authorisationable", "distorted-portal/mixins/routes/dashboardable", "moment"], function (exports, _authorisationable, _dashboardable, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _dashboardable.default, {
    // Attributes
    ability: "read dashboard",
    redirectRoute: "internal.latest",
    //
    model: function model(params) {
      params = this.getRouteParams(params);

      // check if user is a client
      if (Ember.get(this, "session.currentUser.role") === "client") {
        var user = Ember.get(this, "session.currentUser");
        // add client id to data requests
        params["client_id"] = Ember.get(user, "client.id");
      }

      var type = Ember.get(params, "type");
      delete params.type;

      if (type === "programme") {
        return this.getProgrammeData(params);
      } else {
        return this.getPodcastData(params);
      }
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        var start = (0, _moment.default)().subtract(1, "month").format("DD/MM/YY"),
            end = (0, _moment.default)().format("DD/MM/YY");
        controller.set("range", start + ".." + end);
        controller.set("country", null);
        controller.set("type", "podcast");
      }
    }
  });
});