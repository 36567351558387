define('distorted-portal/helpers/dynamic-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dynamicParams = dynamicParams;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function dynamicParams(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 3),
        routeName = _ref2[0],
        params = _ref2[1],
        queryParams = _ref2[2];

    var hash = [];

    // add route
    hash.pushObject(routeName);

    // add route params
    if (Ember.isArray(params)) {
      hash.pushObjects(params);
    } else if (params) {
      hash.pushObject(params);
    }

    // add query params
    if (queryParams) {
      hash.pushObject({
        isQueryParams: true,
        values: queryParams
      });
    }
    return hash;
  }

  exports.default = Ember.Helper.helper(dynamicParams);
});