define('distorted-portal/controllers/internal/programme/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controlers
    programmeController: Ember.inject.controller('internal.programme'),
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: 'Archive Programme',
    // Methods
    redirectSuccess: function redirectSuccess() {
      var clientId = Ember.get(this, 'programmeController.clientId');
      if (clientId) {
        return this.transitionToRoute('internal.client.programmes', clientId);
      } else {
        return this.transitionToRoute('internal.programmes');
      }
    },

    // Actions
    actions: {
      archive: function archive(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');
        analytics.trackEvent('programme.archive.save');

        model.deleteRecord();
        model.save().then(function () {
          toast.info('Programme Archived');
          analytics.trackEvent('programme.archive.success');
          return _this.redirectSuccess();
        }, function () {
          analytics.trackEvent('programme.archive.error');
          return toast.info('Server Error');
        });
      },
      cancel: function cancel(model) {
        var analytics = Ember.get(this, 'analytics');
        analytics.trackEvent('programme.archive.cancel');
        return this.transitionToRoute('internal.programme.index', model.id);
      }
    }
  });
});