define("distorted-portal/helpers/bytes-to-human", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bytesToHuman = bytesToHuman;


  function formatBytes(a, b) {
    if (0 == a) return "0 Bytes";
    var c = 1024,
        d = b || 2,
        e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
  }

  function bytesToHuman(params /*, hash*/) {
    return formatBytes(params[0]);
  }

  exports.default = Ember.Helper.helper(bytesToHuman);
});