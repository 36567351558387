define('distorted-portal/routes/external', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    activate: function activate() {
      // If logged in do not allow access to external routes
      if (Ember.get(this, 'session.isAuthenticated')) {
        this.transitionTo('internal.dashboard');
      }
    }

  });
});