define('distorted-portal/components/audio-group-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Service
    store: Ember.inject.service(),
    // Attributes
    tagName: '',
    // Actions
    actions: {
      saveNewGroup: function saveNewGroup(audioGroup) {
        var action = Ember.get(this, 'onSubmit');
        Ember.set(this, 'isShowingModal', false);
        if (action) {
          return action(audioGroup);
        }
      },
      closeModal: function closeModal() {
        // NO NEED TO ROLLBACK FILES as they are not attached until save called
        // const files = get(this, 'audioGroup.audioGroupFiles');
        // if (get(files, 'length')) {
        //   console.log(files);
        //   files.forEach((file) => {
        //     console.log("rollback", file);
        //     file.rollbackAttributes();
        //   });
        // }
        // get(this, 'audioGroup').rollbackAttributes();
        Ember.set(this, 'isShowingModal', false);
      },
      fileUploaded: function fileUploaded(audioGroup, file) {
        var store = Ember.get(this, 'store');
        store.createRecord('audio-group-file', {
          fileName: file.file_name,
          name: file.path,
          audioGroup: audioGroup
        });
      },
      fileError: function fileError(message) {
        var action = Ember.get(this, 'onFileError');
        if (action) {
          return action(message);
        }
      }
    }
  });
});