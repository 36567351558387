define('distorted-portal/controllers/internal/show/edit', ['exports', 'distorted-portal/mixins/controllers/imageuploadable', 'distorted-portal/utils/errors-to-json'], function (exports, _imageuploadable, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_imageuploadable.default, {
    // Controlers
    showController: Ember.inject.controller('internal.show'),
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: "Update Show",
    // Actions
    actions: {
      submit: function submit(model) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('show.edit.save');
        model.save().then(function () {
          toast.info('Show Updated');
          analytics.trackEvent('show.edit.success');
        }, function (response) {
          var errors = Ember.get(response, 'errors');

          if (errors) {
            // If we have validation errors display the first one
            analytics.trackEvent('show.edit.error', (0, _errorsToJson.default)(errors, true));
            toast.info(Ember.get(response, 'errors.firstObject.detail'));
          } else {
            // else, general error message
            analytics.trackEvent('show.edit.error');
            toast.info('Server Error');
          }
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('show.edit.invalid', errors);
      }
    }
  });
});