define('distorted-portal/mirage/factories/user', ['exports', 'ember-cli-mirage', 'distorted-portal/utils/const/user'], function (exports, _emberCliMirage, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    contactNumber: function contactNumber() {
      return _emberCliMirage.faker.phone.phoneNumber();
    },
    role: function role() {
      return _emberCliMirage.faker.random.arrayElement(_user.ROLE_OPTIONS);
    },
    lastLoginDate: function lastLoginDate() {
      return _emberCliMirage.faker.date.recent();
    },

    asAdmin: (0, _emberCliMirage.trait)({
      role: _user.ADMIN_ROLE
    }),
    asClient: (0, _emberCliMirage.trait)({
      role: _user.CLIENT_ROLE
    }),
    asSyndicator: (0, _emberCliMirage.trait)({
      role: _user.SYNDICATOR_ROLE
    })
  });
});