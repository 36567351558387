define('distorted-portal/controllers/internal/syndicator/syndications', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/errors-to-json'], function (exports, _paginatable, _searchable, _sortable, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Controller
    syndicatorController: Ember.inject.controller('internal.syndicator'),
    // Attributes
    pageTitle: "Syndicator Programmes",
    // Actions
    actions: {
      openNewSyndicationModel: function openNewSyndicationModel(syndicator) {
        var newSyndication = Ember.get(this, 'store').createRecord('programme-syndicator', {
          syndicator: syndicator
        });
        Ember.set(this, 'newSyndication', newSyndication);
        Ember.set(this, 'isShowingModal', true);
      },
      closeNewSyndicationModel: function closeNewSyndicationModel() {
        Ember.set(this, 'isShowingModal', false);
      },
      submitNewSyndication: function submitNewSyndication(newSyndication) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('syndicator.new-programme.save');

        newSyndication.save().then(function () {
          toast.info('Syndication Added');
          Ember.set(_this, 'isShowingModal', false);
          analytics.trackEvent('syndicator.new-programme.success');
          _this.get('target').send('refresh');
        }, function () {
          toast.info('Server Error');
          Ember.set(_this, 'isShowingModal', false);
          analytics.trackEvent('syndicator.new-programme.error');
        });
      },
      archiveSyndication: function archiveSyndication(syndication) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('syndicator.archive-programme.save');

        syndication.deleteRecord();
        syndication.save().then(function () {
          toast.info('Syndication Archived');
          analytics.trackEvent('syndicator.archive-programme.success');
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('syndicator.archive-programme.error');
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('syndicator.programmes.invalid', errors);
      }
    }
  });
});