define('distorted-portal/helpers/get-routes-for', ['exports', 'distorted-portal/utils/const/user', 'distorted-portal/utils/const/client', 'distorted-portal/utils/const/syndicator', 'distorted-portal/utils/const/podcast', 'distorted-portal/utils/const/episode', 'distorted-portal/utils/const/programme', 'distorted-portal/utils/const/show'], function (exports, _user, _client, _syndicator, _podcast, _episode, _programme, _show) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    can: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          model = _ref2[0],
          type = _ref2[1];

      var items = [];
      var ccan = this.get('can');

      if (model === "user") {
        if (type === "resource") {
          items = _user.ACTIONS;
        }
      }
      if (model === "client") {
        if (type === "resource") {
          items = _client.ACTIONS;
        }
      }
      if (model === "syndicator") {
        if (type === "resource") {
          items = _syndicator.ACTIONS;
        }
      }
      if (model === "podcast") {
        if (type === "resource") {
          items = _podcast.ACTIONS;
        }
      }
      if (model === "programme") {
        if (type === "resource") {
          items = _programme.ACTIONS;
        }
      }
      if (model === "episode") {
        if (type === "resource") {
          items = _episode.ACTIONS;
        }
      }
      if (model === "show") {
        if (type === "resource") {
          items = _show.ACTIONS;
        }
      }

      return items.filter(function (x) {
        if (x.ability) {
          return ccan.can(x.ability);
        } else {
          return true;
        }
      });
    }
  });
});