define('distorted-portal/routes/internal/users/trash', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable', 'distorted-portal/mixins/routes/authorisationable', 'distorted-portal/utils/const/user'], function (exports, _paginatable, _searchable, _sortable, _authorisationable, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, _paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    ability: 'archive user',
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);

      params = this.getFilterRoleParam(params);
      params.page = page;
      params.order = sort;
      params.include = 'client,syndicator';
      params.deleted = true; // Request deleted records only


      // Request deleted records only
      return this.store.query('user', params);
    },
    getFilterRoleParam: function getFilterRoleParam(params) {
      switch (Ember.get(this, 'session.currentUser.role')) {
        case _user.ADMIN_ROLE:
          params['role'] = _user.ADMIN_ROLE;
          break;
        case _user.CLIENT_ROLE:
          params['role'] = _user.CLIENT_ROLE;
          params['clientId'] = Ember.get(this, 'session.currentUser.client.id');
          break;
        case _user.SYNDICATOR_ROLE:
          params['role'] = _user.SYNDICATOR_ROLE;
          params['syndicatorId'] = Ember.get(this, 'session.currentUser.syndicator.id');
          break;
      }
      return params;
    }
  });
});