define('distorted-portal/controllers/internal/show/index', ['exports', 'distorted-portal/utils/const/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controllers
    showController: Ember.inject.controller('internal.show'),
    // service
    user: Ember.computed.alias('session.currentUser'),
    // Attributes
    pageTitle: "View Show",
    // Actions
    actions: {},
    // Computed
    isSyndicator: Ember.computed.equal('user.role', _user.SYNDICATOR_ROLE),
    isNotSyndicator: Ember.computed.not('isSyndicator')
  });
});