define('distorted-portal/mirage/factories/audio-group', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.words(3);
    },
    zipFile: function zipFile() {
      return _emberCliMirage.faker.internet.url + '/' + _emberCliMirage.faker.system.fileName;
    },

    withFiles: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(audioGroup, server) {
        var numberOfFiles = _emberCliMirage.faker.random.number({
          min: 1,
          max: 4
        });
        server.createList('audio-group-file', numberOfFiles, {
          audioGroup: audioGroup
        });
      }
    })
  });
});