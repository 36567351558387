define('distorted-portal/routes/internal/latest', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var currentUser = Ember.get(this, 'session.currentUser');

      params.include = 'programme.shows';

      params['syndicator_id'] = Ember.get(currentUser, 'syndicator.id');

      return this.store.query('programme-syndicator', params);
    }
  });
});