define('distorted-portal/routes/internal/podcasts/new', ['exports', 'distorted-portal/mixins/routes/rollbackable'], function (exports, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackable.default, {
    // Attributes
    queryParams: {
      clientId: {
        refreshModel: true
      }
    },
    // Methods
    model: function model() {
      return this.store.createRecord('podcast');
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('clientId', null);
      }
    }
  });
});