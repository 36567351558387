define("distorted-portal/mixins/components/downloadable-chart", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Service
    countryService: Ember.inject.service(),

    addFullCountryNameToData: function addFullCountryNameToData(data) {
      var _this = this;

      return data.map(function (item) {
        var fullName = Ember.get(_this, "countryService").nameForCode(item[0]);
        return ["\"" + fullName + "\"", item[1]];
      });
    },
    // Actions
    actions: {
      downloadChart: function downloadChart(data, convertCountryCode) {
        var csvContent = "data:text/csv;charset=utf-8,";
        var dataString = "";

        if (convertCountryCode) {
          data = this.addFullCountryNameToData(data);
        }

        data.forEach(function (infoArray, index) {
          dataString = infoArray.join(",");
          csvContent += index < data.length ? dataString + "\n" : dataString;
        });
        var encodedUri = encodeURI(csvContent);
        //window.open(encodedUri);

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "export.csv");
        link.click();
      }
    }
  });
});