define("distorted-portal/mirage/fixtures/categories", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "name": "Arts"
  }, {
    "name": "Business"
  }, {
    "name": "Comedy"
  }, {
    "name": "Education"
  }, {
    "name": "Government & Organizations"
  }, {
    "name": "Health"
  }, {
    "name": "Music"
  }, {
    "name": "News & Politics"
  }, {
    "name": "Science & Medicine"
  }, {
    "name": "Society & Culture"
  }, {
    "name": "Sports & Recreation"
  }, {
    "name": "Technology"
  }, {
    "name": "Kids & Family"
  }, {
    "name": "TV & Film"
  }, {
    "name": "Religion & Spirituality"
  }, {
    "name": "Games & Hobbies"
  }];
});