define('distorted-portal/routes/internal/syndicator', ['exports', 'distorted-portal/mixins/routes/authorisationable'], function (exports, _authorisationable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, {
    ability: 'view syndicator',
    // Methods
    model: function model(params) {
      return this.store.findRecord('syndicator', params.syndicator_id, {
        include: ''
      });
    }
  });
});