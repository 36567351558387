define('distorted-portal/components/episode-detail-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    icon: 'arrow_back',
    text: 'back',
    // Computed
    hasPodcast: Ember.computed.bool('podcastId'),
    hasParent: Ember.computed.alias('hasPodcast'),
    parentId: Ember.computed.alias('podcastId'),
    parentRoute: Ember.computed('hasPodcast', function () {
      return Ember.get(this, 'hasPodcast') ? 'internal.podcast.episodes' : 'internal.episodes';
    })
  });
});