define('distorted-portal/mixins/validations/user', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', true)],
    lastName: [(0, _emberCpValidations.validator)('presence', true)],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    oldPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 255,
      message: '8 characters or more'
    })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 255,
      message: '8 characters or more'
    })],
    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })],
    contactNumber: [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
      message: 'Please enter a valid phone number'
    })]
  });
});