define('distorted-portal/components/account-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'items', [{
        title: 'Details',
        route: 'internal.account.index'
      }, {
        title: 'Password',
        route: 'internal.account.password'
      }]);
    }
  });
});