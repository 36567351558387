define('distorted-portal/controllers/internal/programmes/trash', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/const/programme'], function (exports, _paginatable, _searchable, _sortable, _programme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Programme Trash',
    sort: 'title',
    sortOptions: _programme.SORT_OPTIONS,
    grid: true
  });
});