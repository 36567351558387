define('distorted-portal/utils/errors-to-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = errorsToJSON;
  function errorsToJSON(model) {
    var jsonapi = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (jsonapi) {
      return model.map(function (error) {
        return {
          attribute: Ember.get(error, 'source.pointer'),
          message: Ember.get(error, 'detail'),
          type: Ember.get(error, 'title')
        };
      });
    } else {
      var errors = Ember.get(model, 'validations.errors');

      return errors.map(function (error) {
        return {
          attribute: Ember.get(error, 'attribute'),
          message: Ember.get(error, 'message'),
          type: Ember.get(error, 'type')
        };
      });
    }
  }
});