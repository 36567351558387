define('distorted-portal/mixins/validations/programme-syndication', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    showDay: [(0, _emberCpValidations.validator)('presence', true)],
    showTime: [(0, _emberCpValidations.validator)('presence', true)],
    audienceSize: [(0, _emberCpValidations.validator)('presence', true)]
  });
});