define('distorted-portal/components/syndication-mobile-actions', ['exports', 'distorted-portal/utils/const/syndication'], function (exports, _syndication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    items: _syndication.ACTIONS
  });
});