define('distorted-portal/mirage/factories/client', ['exports', 'ember-cli-mirage', 'distorted-portal/utils/const/client'], function (exports, _emberCliMirage, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    countryCode: function countryCode() {
      return _emberCliMirage.faker.random.arrayElement(['US', 'UK', 'DE', 'FR', 'AS']);
    },
    deletedAt: function deletedAt() {
      return null;
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    facebook: function facebook(i) {
      // return faker.internet.userName();
      return _emberCliMirage.faker.list.random('DonaldTrump', 'IvankaTrump', 'nigelfarageofficial')(i);
    },
    image: function image() {
      return _emberCliMirage.faker.image.imageUrl(320, 320, undefined, true);
    },
    twitter: function twitter(i) {
      // return faker.internet.userName();
      return _emberCliMirage.faker.list.random('realDonaldTrump', 'ChiefKeef', 'Nigel_Farage')(i);
    },
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    service: function service() {
      return _emberCliMirage.faker.random.arrayElement(_client.SERVICE_OPTIONS);
    },
    website: function website(i) {
      // return faker.internet.url();
      return _emberCliMirage.faker.list.random('https://trello.com/', 'https://www.google.co.uk/maps', 'https://hexdocs.pm/ecto/Ecto.Repo.html#c:insert_all/3')(i);
    }
  });
});