define('distorted-portal/controllers/internal/syndicator/users', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/const/user'], function (exports, _paginatable, _searchable, _sortable, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Controller
    syndicatorController: Ember.inject.controller('internal.syndicator'),
    // Attributes
    pageTitle: "Syndicator Users",
    sort: 'lastName',
    sortOptions: _user.SORT_OPTIONS
  });
});