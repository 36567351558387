define('distorted-portal/components/chart/podcast-daily-downloads', ['exports', 'distorted-portal/mixins/components/downloadable-chart', 'distorted-portal/mixins/components/printable-chart', 'distorted-portal/mixins/components/dashboard-chart'], function (exports, _downloadableChart, _printableChart, _dashboardChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dashboardChart.default, _downloadableChart.default, _printableChart.default, {
    stats: Ember.computed('data', function () {
      var data = this.get('data'),
          totalDownloads = 0,
          averageDownloads = 0;

      if (data && data.length > 1) {

        data.forEach(function (row, index) {
          if (index > 0) {
            totalDownloads = totalDownloads + row[1];
          }
        });

        averageDownloads = totalDownloads / (data.length - 1);
      }

      return {
        totalDownloads: totalDownloads,
        averageDownloads: Math.round(averageDownloads)
      };
    }),
    options: Ember.computed(function () {
      var bColor = '#FAFAFA',
          color = '#212121',
          lColor = '#424242';

      return {
        animation: {
          duration: 2,
          startup: true,
          easing: 'inAndOut'
        },
        annotations: {
          alwaysOutside: false
        },
        backgroundColor: bColor,
        colors: ['#EA80FC', '#E040FB', '#D500F9', '#AA00FF', '#AA00FF'],
        chartArea: {
          left: 75, //48,
          top: 12.5, //32,
          right: 100, //96,
          bottom: 75 //48,
        },
        height: 450,
        fontName: 'Lato',
        titleTextStyle: {
          color: color
        },
        legend: false,
        isStacked: false,
        areaOpacity: 0.6,
        hAxis: {
          baselineColor: lColor,
          gridlines: {
            color: lColor,
            count: 4
          },
          slantedText: false,
          showTextEvery: 7,
          title: 'Date',
          textStyle: {
            color: color
          },
          titleTextStyle: {
            color: color
          }
        },
        vAxis: {
          baselineColor: '#E0E0E0',
          gridlines: {
            color: '#E0E0E0',
            count: 5
          },
          minValue: 0,
          viewWindow: {
            min: 0
          },
          textStyle: {
            color: color
          },
          titleTextStyle: {
            color: color
          }
        }
      };
    })
  });
});