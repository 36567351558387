define('distorted-portal/mixins/controllers/imageuploadable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      imageUpdated: function imageUpdated(model, imagePath) {
        var toast = Ember.get(this, 'toast');
        toast.info('Image Uploaded. Click Save to update the database');
        Ember.set(model, 'image', imagePath);
      }
    }
  });
});