define('distorted-portal/services/country-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // Services
    store: Ember.inject.service(),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.set('countries', []);
      // ========
      // REMEMBER - Countries are loaded into the store in the internal route
      // so this service is seeded!
      // ========
    },
    nameForCode: function nameForCode(code) {
      var countries = this.get('store').peekAll('country');

      // we assume countries loaded as we load them in the application route
      if (countries.get('length')) {
        var country = countries.findBy('code', code),
            name = country ? country.get('name') : code;

        return name;
      }
      return code;
    },
    codeForName: function codeForName(name) {
      var countries = this.get('store').peekAll('country');
      // we assume countries loaded as we load them in the application route
      if (countries.get('length')) {
        var country = countries.findBy('code', name),
            code = country ? country.get('name') : name;
        return code;
      }
      return name;
    },
    objectForCode: function objectForCode(code) {
      var countries = this.get('store').peekAll('country');
      // we assume countries loaded as we load them in the application route
      if (countries.get('length')) {
        var country = countries.findBy('code', code),
            ret = country ? country : code;
        return ret;
      }
      return code;
    }
  });
});