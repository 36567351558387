define('distorted-portal/routes/internal/programme/shows', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable'], function (exports, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);

      params.page = page;
      params.order = sort;
      params.include = 'programme.client';

      params.programme_id = this.paramsFor('internal.programme').programme_id;

      return this.store.query('show', params);
    }
  });
});