define('distorted-portal/utils/get-upload-url', ['exports', 'distorted-portal/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getUploadUrl;
  function getUploadUrl() {
    var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    return path.indexOf('http') === 0 ? path : '' + _environment.default.rootApiURL + path;
  }
});