define('distorted-portal/controllers/internal/syndicator/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controllers
    syndicatorControllers: Ember.inject.controller("internal.syndicator.index"),
    //
    pageTitle: "View Syndicator"
  });
});