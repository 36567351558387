define('distorted-portal/controllers/internal/users/new', ['exports', 'distorted-portal/utils/errors-to-json'], function (exports, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Service
    analytics: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Attributes
    queryParams: ['clientId', 'syndicatorId'],
    clientId: null,
    syndicatorId: null,
    pageTitle: "New User",
    // Methods
    redirectSuccess: function redirectSuccess() {
      var clientId = Ember.get(this, 'clientId'),
          syndicatorId = Ember.get(this, 'syndicatorId');

      if (clientId) {
        return this.transitionToRoute('internal.client.users', clientId);
      }
      if (syndicatorId) {
        return this.transitionToRoute('internal.syndicator.users', syndicatorId);
      } else {
        return this.transitionToRoute('internal.users');
      }
    },

    // Actions
    actions: {
      submit: function submit(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('user.new.save');

        model.save().then(function () {
          toast.info('User Created');
          analytics.trackEvent('user.new.success');
          _this.redirectSuccess();
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('user.new.error');
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('user.new.invalid', errors);
      }
    }
  });
});