define("distorted-portal/mixins/components/dashboard-chart", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Properties
    onToggleFilter: function onToggleFilter() {},

    // Actions
    actions: {
      toggleFilter: function toggleFilter() {
        this.onToggleFilter();
      }
    }
  });
});