define('distorted-portal/mirage/models/programme', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    category: (0, _emberCliMirage.belongsTo)(),
    client: (0, _emberCliMirage.belongsTo)(),
    language: (0, _emberCliMirage.belongsTo)(),
    shows: (0, _emberCliMirage.hasMany)(),
    syndications: (0, _emberCliMirage.hasMany)('programme-syndicator')
  });
});