define('distorted-portal/components/syndication-grid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    actionRoute: null,
    actionIcon: 'keyboard_arrow_right',
    tagName: '',
    // Computed
    isProgrammeList: Ember.computed.bool('programme'),
    isSyndicatorList: Ember.computed.bool('syndicator'),
    hasAction: Ember.computed.bool('actionRoute')
  });
});