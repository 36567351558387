define('distorted-portal/mirage/factories/syndicator', ['exports', 'ember-cli-mirage', 'distorted-portal/utils/const/syndicator'], function (exports, _emberCliMirage, _syndicator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    website: function website() {
      return _emberCliMirage.faker.internet.url();
    },
    twitter: function twitter() {
      return _emberCliMirage.faker.internet.userName();
    },
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    image: function image() {
      return _emberCliMirage.faker.image.imageUrl();
    },
    facebook: function facebook() {
      return _emberCliMirage.faker.internet.userName();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    countryCode: function countryCode() {
      return _emberCliMirage.faker.random.arrayElement(['US', 'UK', 'DE', 'FR', 'AS']);
    },
    city: function city() {
      return _emberCliMirage.faker.address.city();
    },
    platform: function platform() {
      return _emberCliMirage.faker.random.arrayElement(_syndicator.SYNDICATOR_PLATFORMS);
    },
    weeklyReach: function weeklyReach() {
      return _emberCliMirage.faker.random.arrayElement(_syndicator.SYNDICATOR_REACH).value;
    },
    totalSyndications: function totalSyndications() {
      return Math.floor(Math.random() * 10);
    }
  });
});