define('distorted-portal/routes/internal/client/dashboard', ['exports', 'distorted-portal/mixins/routes/dashboardable'], function (exports, _dashboardable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_dashboardable.default, {
    model: function model(params) {

      params = this.getRouteParams(params);

      // add client id
      params['client_id'] = this.paramsFor('internal.client').client_id;

      var type = Ember.get(params, 'type');
      delete params.type;

      if (type === 'programme') {
        return this.getProgrammeData(params);
      } else {
        return this.getPodcastData(params);
      }
    }
  });
});