define('distorted-portal/components/user-detail-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    icon: 'arrow_back',
    text: 'back',
    // Computed
    hasSyndicator: Ember.computed.bool('syndicatorId'),
    hasClient: Ember.computed.bool('clientId'),
    hasParent: Ember.computed.or('hasClient', 'hasSyndicator'),
    parentId: Ember.computed('clientId', 'syndicatorId', function () {
      var clientId = Ember.get(this, 'clientId'),
          syndicatorId = Ember.get(this, 'syndicatorId');
      return clientId ? clientId : syndicatorId;
    }),
    parentRoute: Ember.computed('hasClient', function () {
      return Ember.get(this, 'hasClient') ? 'internal.client.users' : 'internal.syndicator.users';
    })
  });
});