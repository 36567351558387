define('distorted-portal/routes/internal/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // Attributes
    queryParams: {
      programmeId: {
        refreshModel: true
      },
      syndicationId: {
        refreshModel: true
      },
      latest: {
        refreshModel: false
      }
    },
    // Methods
    model: function model(params) {
      return this.store.findRecord('show', params.show_id, {
        include: 'category,programme.client,language'
      });
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('programmeId', null);
        controller.set('syndicationId', null);
        controller.set('latest', null);
      }
    }
  });
});