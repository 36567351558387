define('distorted-portal/routes/internal/shows/trash', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable'], function (exports, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params);
      // Request deleted records only
      return this.store.query('show', {
        filter: params,
        page: page,
        sort: sort,
        include: 'programme.client',
        deleted: true
      });
    }
  });
});