define('distorted-portal/controllers/internal/episode/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controlers
    episodeController: Ember.inject.controller('internal.episode'),
    // Attributes
    pageTitle: "View Episode"
  });
});