define('distorted-portal/components/chart/programme-show-times', ['exports', 'distorted-portal/mixins/components/downloadable-chart', 'distorted-portal/mixins/components/dashboard-chart'], function (exports, _downloadableChart, _dashboardChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dashboardChart.default, _downloadableChart.default, {
    dayData: Ember.A([['Day', 'Listeners']]),
    timeData: Ember.A([['Time', 'Listeners']]),
    stats: Ember.computed('timeData', 'dayData', function () {
      var timeData = this.get('timeData'),
          dayData = this.get('dayData'),
          topDay = 'n/a',
          topTime = 'n/a';

      if (timeData && timeData.length > 1) {
        topTime = timeData[1][0];
      }
      if (dayData && dayData.length > 1) {
        topDay = dayData[1][0];
      }
      return {
        topTime: topTime,
        topDay: topDay
      };
    }),
    options: Ember.computed(function () {
      return {
        chartArea: {
          top: 25,
          bottom: 25
        },
        pieHole: 0.4,
        colors: ['#82B1FF', '#448AFF', '#2979FF', '#2962FF'],
        height: 450,
        legend: {
          position: 'bottom'
        }
      };
    })
  });
});