define('distorted-portal/routes/internal/syndications', ['exports', 'distorted-portal/mixins/routes/paginatable', 'distorted-portal/mixins/routes/searchable', 'distorted-portal/mixins/routes/sortable'], function (exports, _paginatable, _searchable, _sortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatable.default, _searchable.default, _sortable.default, {
    // Attributes
    queryParams: {},
    // Methods
    model: function model(params) {
      var sort = this.getSortParam(params),
          page = this.getPageParams(params),
          currentUser = Ember.get(this, 'session.currentUser');

      params.page = page;
      params.order = sort;
      params.include = 'programme';

      params['syndicator_id'] = Ember.get(currentUser, 'syndicator.id');

      return this.store.query('programme-syndicator', params);
    }
  });
});