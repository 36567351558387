define("distorted-portal/components/form-file-upload", ["exports", "distorted-portal/utils/get-upload-url"], function (exports, _getUploadUrl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isUploading: false,
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, "dropzone").destroy();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      // Find any classes within the current component that were inserted by Dropzone.
      // This signifies that Dropzone has been initialized.
      var dropzoneClass = this.$().find(".dz-default");

      // Only initialize dropzone if it hasn't been already.
      if (dropzoneClass.length !== 0) {
        return;
      }

      var options = Ember.get(this, "options"),
          dropzone = new Dropzone("#audio-dropzone", options),
          action = Ember.get(this, "onUpload"),
          errorAction = Ember.get(this, "onError");

      // called when a file gets processed
      dropzone.on("processing", function () /*file*/{
        Ember.set(_this, "isUploading", true);
      });
      dropzone.on("error", function (error, errorMessage /*, xhr*/) {
        Ember.set(_this, "isUploading", false);
        var message = "Error Uploading File, please try again";
        if (errorMessage == "Server responded with 0 code") {
          message = "Upload timed out, please try again";
        }
        if (errorAction) {
          errorAction(message);
        }
      });
      dropzone.on("canceled", function () {
        Ember.set(_this, "isUploading", false);
      });
      // doing this here rather than in the config object
      // so we have easy access to the model, key & dropbox vars
      dropzone.on("successmultiple", function (files, response) {
        Ember.$.each(files, function (index, uploadFile) {
          if (uploadFile.status === "success") {
            // Action will remove the uploaded file from dropzone
            dropzone.removeFile(uploadFile);
          }
        });

        Ember.$.each(response, function (index, newFile) {
          // Action will add the new file to the group model
          if (action) {
            action(newFile);
          }
        });
        Ember.set(_this, "isUploading", false);
      });

      Ember.set(this, "dropzone", dropzone);
    },

    // Events
    addedFileEvent: function addedFileEvent() /*file*/{
      if (this.files.length > 1) {
        this.removeFile(this.files[0]);
      }
    },

    // Computed
    image: Ember.computed.alias("model.image"),
    options: Ember.computed(function () {
      return {
        acceptedFiles: null,
        addRemoveLinks: true,
        clickable: true,
        method: "post",
        paramName: "file",
        url: (0, _getUploadUrl.default)("/file/upload"),
        maxFilesize: 500, // MB
        maxFiles: null,
        timeout: 3600000, // 1 hour for realllly slow connections 🐌
        uploadMultiple: true,
        // addedfile: this.addedFileEvent,
        previewTemplate: document.querySelector("#dropzone-template").innerHTML,
        dictCancelUpload: "cancel",
        dictRemoveFile: "remove"
      };
    })
  });
});