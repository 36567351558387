define('distorted-portal/models/programme-syndicator', ['exports', 'ember-data', 'distorted-portal/mixins/validations/programme-syndication', 'moment'], function (exports, _emberData, _programmeSyndication, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_programmeSyndication.default, {
    // Attributes
    audienceSize: attr('number'),
    needsNewData: attr('boolean'),
    showDay: attr('string'),
    showTime: attr('string'),
    insertedAt: attr('date'),
    // Relationships
    programme: belongsTo(),
    syndicator: belongsTo(),
    // Computed
    showTimeString: Ember.computed('showTime', function () {
      var time = Ember.get(this, 'showTime');
      if (time) {
        return time.substring(0, 5);
      }
    }),
    showTimeSet: Ember.computed.bool('showDay'),
    isNew: Ember.computed('resource', function () {
      var insertedAt = (0, _moment.default)(Ember.get(this, 'insertedAt')),
          now = (0, _moment.default)().subtract(4, 'weeks').utc();
      return insertedAt.isAfter(now);
    }),
    audienceSizeStyle: Ember.computed('audienceSize', function () {
      switch (Ember.get(this, 'audienceSize')) {
        case 10000:
          return 'reach-1';
        case 50000:
          return 'reach-2';
        case 100000:
          return 'reach-3';
        case 250000:
          return 'reach-4';
        case 500000:
          return 'reach-5';
        case 1000000:
          return 'reach-6';
        case 5000000:
          return 'reach-7';
        default:
          return 'grey';
      }
    })
  });
});