define('distorted-portal/router', ['exports', 'ember-router-scroll', 'distorted-portal/config/environment'], function (exports, _emberRouterScroll, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    // Services
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var path = _this.get('url'),
            name = _this.getWithDefault('currentRouteName', 'unknown'),
            user = Ember.get(_this, 'session.currentUser');

        if (user) {
          Ember.get(_this, 'analytics').setUser(user.id);
        }

        Ember.get(_this, 'analytics').trackVisit(path, name);
      });
    }
  });

  Router.map(function () {
    this.route('external', {
      path: 'auth'
    }, function () {
      this.route('login');
      this.route('recover-password', {
        path: 'recover'
      });
      this.route('reset-password', {
        path: 'reset/:token'
      });
    });

    this.authenticatedRoute('internal', {
      path: '/'
    }, function () {
      this.route('dashboard', {
        path: '/'
      });
      // Account
      this.route('account', function () {
        this.route('password');
      });
      // Users
      this.route('users', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('user', {
        path: 'user/:user_id'
      }, function () {
        this.route('edit');
        this.route('password');
        this.route('archive');
      });
      // Clients
      this.route('clients', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('client', {
        path: 'client/:client_id'
      }, function () {
        this.route('edit');
        this.route('podcasts');
        this.route('programmes');
        this.route('users');
        this.route('archive');
        this.route('dashboard');
      });
      // Syndicators
      this.route('syndicators', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('syndicator', {
        path: 'syndicator/:syndicator_id'
      }, function () {
        this.route('edit');
        this.route('syndications');
        this.route('users');
        this.route('archive');
      });
      // Podcasts
      this.route('podcast', {
        path: 'podcast/:podcast_id'
      }, function () {
        this.route('edit');
        this.route('archive');
        this.route('episodes');
        this.route('dashboard');
      });
      this.route('podcasts', function () {
        this.route('new');
        this.route('trash');
      });
      // Episodes
      this.route('episodes', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('episode', {
        path: '/episode/:episode_id'
      }, function () {
        this.route('edit');
        this.route('archive');
        this.route('dashboard');
      });
      // Programmes
      this.route('programmes', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('programme', {
        path: 'programme/:programme_id'
      }, function () {
        this.route('edit');
        this.route('shows');
        this.route('syndications');
        this.route('archive');
        this.route('dashboard');
      });
      // Shows
      this.route('shows', function () {
        this.route('new');
        this.route('trash');
      });
      this.route('show', {
        path: 'show/:show_id'
      }, function () {
        this.route('edit');
        this.route('files');
        this.route('archive');
        this.route('dashboard');
      });
      // Syndications
      this.route('syndications');
      this.route('syndication', {
        path: 'syndication/:programme_syndicator_id'
      }, function () {
        this.route('shows');
        // Route just here to redirect links from
        // old portals email links
        this.route('show', {
          path: 'show/:show_id'
        });
      });
      // Latest Shows
      this.route('latest');
      // Auth
      this.route('logout');
    });

    this.route('not-found', {
      path: '/*path'
    });
  });

  exports.default = Router;
});