define('distorted-portal/components/chart/podcast-device-downloads', ['exports', 'distorted-portal/mixins/components/downloadable-chart', 'distorted-portal/mixins/components/printable-chart', 'distorted-portal/mixins/components/dashboard-chart'], function (exports, _downloadableChart, _printableChart, _dashboardChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dashboardChart.default, _downloadableChart.default, _printableChart.default, {
    // Attributes
    deviceData: Ember.A([['Platform', 'Usage']]),
    platformData: Ember.A([['Device', 'Usage']]),
    // Computed
    stats: Ember.computed('deviceData', 'platformData', function () {
      var deviceData = this.get('deviceData'),
          platformData = this.get('platformData'),
          topPlatform = 'n/a',
          topDevice = 'n/a';

      if (deviceData && deviceData.length > 1) {
        topDevice = deviceData[1][0];
      }
      if (platformData && platformData.length > 1) {
        topPlatform = platformData[1][0];
      }
      return {
        topDevice: topDevice,
        topPlatform: topPlatform
      };
    }),
    options: Ember.computed(function () {
      return {
        chartArea: {
          top: 25,
          bottom: 25
        },
        pieHole: 0.4,
        colors: ['#82B1FF', '#448AFF', '#2979FF', '#2962FF'],
        height: 450,
        legend: {
          position: 'bottom'
        }
      };
    })
  });
});