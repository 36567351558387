define('distorted-portal/mirage/helpers/session-handlers', ['exports', 'distorted-portal/mirage/helpers/responses'], function (exports, _responses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getSession = getSession;
  exports.deleteSession = deleteSession;
  exports.postSession = postSession;
  function getSession(db) {

    var userId = window.localStorage.getItem('sessionUserId');

    if (userId) {
      var user = db.users.find(userId);

      if (user) {
        return user;
      }
    }
    var errors = [];
    return (0, _responses.errorResponse)(errors);
  }

  function deleteSession() {
    return (0, _responses.successResponse)(204);
  }

  function postSession(db, request) {

    var body = JSON.parse(request.requestBody),
        result = db.users.where({
      email: body.data.attributes.email
    }),
        user = void 0,
        errors = [];

    if (body.data.attributes.password === 'wrong') {
      errors.push({
        status: "422",
        source: {
          pointer: "/data/attributes/password"
        },
        title: "Invalid Attribute",
        detail: "Password is incorrect."
      });
      return (0, _responses.errorResponse)(errors);
    }

    if (result.length) {
      user = result.models[0];
      user.token = user.id;
      window.localStorage.setItem('sessionUserId', user.id);
      return user;
    }

    errors.push({
      status: "422",
      source: {
        pointer: "/data/attributes/email"
      },
      title: "Invalid Attribute",
      detail: "Email is incorrect."
    });

    return (0, _responses.errorResponse)(errors);
  }
});