define('distorted-portal/components/archive-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    // Actions
    actions: {
      archive: function archive() {
        var action = Ember.get(this, "onConfirm");
        if (action) {
          return action();
        }
      },
      cancel: function cancel() {
        var action = Ember.get(this, "onCancel");
        if (action) {
          return action();
        }
      }
    }
  });
});