define('distorted-portal/torii-adapters/application', ['exports', 'distorted-portal/utils/get-api-url'], function (exports, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    // Services
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Methods
    open: function open(authorization) {
      var store = this.get('store');

      var data = authorization.data;

      localStorage.setItem("auth_token", data.attributes.token);
      localStorage.setItem("auth_user", data.id);

      var userRecord;
      userRecord = store.peekRecord('user', data.id);

      Ember.set(this, 'analytics.user', data.id);

      if (!userRecord) {
        store.pushPayload(authorization);
      }
      return new Ember.RSVP.Promise(function (resolve) {
        var user = store.peekRecord('user', data.id);
        resolve({
          currentUser: user
        });
      });
    },

    fetch: function fetch() {
      var store = Ember.get(this, 'store'),
          analytics = Ember.get(this, 'analytics');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var token = localStorage.getItem("auth_token");
        // Ember.Logger.log('token ' + token);
        if (token !== undefined && token !== null) {
          // We only want to attempt to get the current user
          // if we have a token present
          Ember.$.ajax({
            url: (0, _getApiUrl.default)('/current_user'),
            data: {
              include: 'client,syndicator'
            },
            success: function success(response) {
              store.pushPayload(response);
              var user = store.peekRecord('user', response.data.id);

              Ember.set(analytics, 'user', user.id);

              resolve({
                currentUser: user
              });
            },
            error: Ember.run.bind(null, reject)
          });
        } else {
          // Ember.Logger.log('reject');
          reject();
        }
      });
    },

    close: function close() {

      var analytics = Ember.get(this, 'analytics');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: (0, _getApiUrl.default)('/users/sign_out'),
          type: 'DELETE',
          success: Ember.run.bind(null, resolve),
          error: Ember.run.bind(null, reject)
        });
        resolve();
      }).then(function () {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_user");
        Ember.set(analytics, 'user', null);
        return;
      }).catch(function (error) {
        return Ember.RSVP.reject(error);
      });
    }

  });
});