define('distorted-portal/mirage/fixtures/languages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }, {
    name: 'German',
    code: 'de'
  }, {
    name: 'Italian',
    code: 'it'
  }, {
    name: 'Korean',
    code: 'ko'
  }, {
    name: 'Norwegian',
    code: 'no'
  }, {
    name: 'Polish',
    code: 'pl'
  }, {
    name: 'Russian',
    code: 'ru'
  }, {
    name: 'Swedish',
    code: 'sv'
  }, {
    name: 'Turkish',
    code: 'tr'
  }, {
    name: 'Japanese',
    code: 'ja'
  }, {
    name: 'Chinese',
    code: 'zh'
  }, {
    name: 'Arabic',
    code: 'ar'
  }];
});