define('distorted-portal/components/show-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    defaultRoute: 'internal.show.index',
    // Computed
    queryParams: Ember.computed('programme.id', 'syndication.id', function () {

      var params = {},
          programmeId = Ember.get(this, 'programme.id'),
          syndicationId = Ember.get(this, 'syndication.id');

      if (programmeId) {
        params.programmeId = programmeId;
      }
      if (syndicationId) {
        params.syndicationId = syndicationId;
      }

      return params;
    }),
    hasProgramme: Ember.computed.bool('programme'),
    hasSyndication: Ember.computed.bool('syndication')
  });
});