define('distorted-portal/mirage/factories/programme-syndicator', ['exports', 'ember-cli-mirage', 'distorted-portal/utils/const/syndicator'], function (exports, _emberCliMirage, _syndicator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    audienceSize: function audienceSize() {
      return _emberCliMirage.faker.random.arrayElement(_syndicator.SYNDICATOR_REACH).value;
    },
    needsNewData: function needsNewData() {
      return _emberCliMirage.faker.random.boolean();
    },
    showDay: function showDay() {
      return _emberCliMirage.faker.random.arrayElement(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
    },
    showTime: function showTime() {
      return _emberCliMirage.faker.random.arrayElement(['00:00:00', '01:00:00', '02:00:00', '03:00:00', '04:00:00', '05:00:00', '06:00:00', '07:00:00', '08:00:00', '09:00:00', '10:00:00', '11:00:00', '12:00:00', '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00', '20:00:00', '21:00:00', '22:00:00', '23:00:00']);
    },
    insertedAt: function insertedAt() {
      if (_emberCliMirage.faker.random.number({
        max: 3
      }) === 0) {
        return _emberCliMirage.faker.date.recent();
      }
      return _emberCliMirage.faker.date.past();
    }
  });
});