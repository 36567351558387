define('distorted-portal/controllers/internal/show/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controlers
    showController: Ember.inject.controller('internal.show'),
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: 'Archive Show',
    // Methods
    redirectSuccess: function redirectSuccess() {
      var programmeId = Ember.get(this, 'showController.programmeId');
      if (programmeId) {
        return this.transitionToRoute('internal.programme.shows', programmeId);
      } else {
        return this.transitionToRoute('internal.shows');
      }
    },

    // Actions
    actions: {
      archive: function archive(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('show.archive.save');

        model.deleteRecord();
        model.save().then(function () {
          toast.info('Show Archived');
          analytics.trackEvent('show.archive.success');
          return _this.redirectSuccess();
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('show.archive.error');
        });
      },
      cancel: function cancel(model) {
        var analytics = Ember.get(this, 'analytics');
        analytics.trackEvent('show.archive.cancel');
        return this.transitionToRoute('internal.show.index', model.id);
      }
    }
  });
});