define('distorted-portal/mixins/routes/searchable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      search: {
        refreshModel: true
      }
    }
  });
});