define('distorted-portal/utils/const/episode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Title',
    value: 'title'
  }, {
    label: 'Author',
    value: 'author'
  }, {
    label: 'Client',
    value: 'client.name'
  }];
  var ACTIONS = exports.ACTIONS = [{
    title: 'Dashboard',
    route: 'internal.episode.dashboard',
    name: 'dashboard',
    ability: 'read episode'
  }, {
    title: 'View Episode',
    route: 'internal.episode.index',
    name: 'view',
    ability: 'read episode'
  }, {
    title: 'Edit Episode',
    route: 'internal.episode.edit',
    name: 'edit',
    ability: 'update episode'
  }, {
    title: 'Archive Episode',
    route: 'internal.episode.archive',
    name: 'archive',
    ability: 'archive episode'
  }];
});