define('distorted-portal/controllers/internal/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['programmeId', 'syndicationId', 'latest'],
    programmeId: null,
    syndicationId: null,
    latest: null
  });
});