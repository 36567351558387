define('distorted-portal/mirage/serializers/application', ['exports', 'ember-mirage-sauce/mirage-serializers/json-api-serializer'], function (exports, _jsonApiSerializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApiSerializer.default.extend({
    filterHook: function filterHook(json, request) {

      // Are we requesting deleted or non deleted resources
      var showDeleted = !!request.queryParams['deleted'];

      // Deleted At filter
      json.data = json.data.filter(function (resource) {
        // is this resource deleted?
        var deletedAt = !Ember.isEmpty(Ember.get(resource, 'attributes.deleted-at'));
        // if both true or both false return true
        return showDeleted === deletedAt;
      });

      return json;
    }
  });
});