define('distorted-portal/abilities/syndicator', ['exports', 'ember-can', 'distorted-portal/utils/const/user'], function (exports, _emberCan, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    // Service
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    // Computed
    isAdmin: Ember.computed.equal('user.role', _user.ADMIN_ROLE),
    isAdminOrClient: Ember.computed.or('user.role', _user.ADMIN_ROLE, _user.CLIENT_ROLE),
    // Computed
    isClient: Ember.computed.equal('user.role', _user.CLIENT_ROLE),
    // Computed
    isSyndicator: Ember.computed.equal('user.role', _user.SYNDICATOR_ROLE),
    // Computed
    isAny: Ember.computed.or('user.role', _user.ADMIN_ROLE, _user.CLIENT_ROLE, _user.SYNDICATOR_ROLE),
    // only admins can create an ideal user
    canCreate: Ember.computed.alias('isAdmin'),
    // only admins can read an ideal user
    canRead: Ember.computed.alias('isAdmin'),
    //
    canView: Ember.computed.alias('isAdminOrClient'),
    // only admins can update an ideal user
    canUpdate: Ember.computed.alias('isAdmin'),
    // only admins can archive an ideal user
    canArchive: Ember.computed.alias('isAdmin')
  });
});