define('distorted-portal/controllers/internal/syndicators/new', ['exports', 'distorted-portal/mixins/controllers/imageuploadable', 'distorted-portal/utils/errors-to-json'], function (exports, _imageuploadable, _errorsToJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_imageuploadable.default, {
    // Service
    toast: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Attributes
    pageTitle: "New Syndicator",
    // Actions
    actions: {
      submit: function submit(model) {
        var _this = this;

        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics');

        analytics.trackEvent('syndicator.new.save');

        model.save().then(function () {
          toast.info('Syndicator Created');
          analytics.trackEvent('syndicator.new.success');
          _this.transitionToRoute('internal.syndicators.index');
        }, function () {
          toast.info('Server Error');
          analytics.trackEvent('syndicator.new.error');
        });
      },
      invalid: function invalid(model /*, errors*/) {
        var toast = Ember.get(this, 'toast'),
            analytics = Ember.get(this, 'analytics'),
            errors = (0, _errorsToJson.default)(model);

        toast.info('Please correct form errors');
        analytics.trackEvent('syndicator.new.invalid', errors);
      }
    }
  });
});