define('distorted-portal/models/country', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    alpha2: attr('string'),
    name: attr('string'),
    code: Ember.computed.alias('alpha2')
  });
});