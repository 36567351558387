define('distorted-portal/controllers/internal/client/podcasts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controller
    clientController: Ember.inject.controller('internal.client'),
    // Attributes
    pageTitle: "Client Podcasts"
  });
});