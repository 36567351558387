define('distorted-portal/components/show-mobile-actions', ['exports', 'distorted-portal/utils/const/show', 'ember-can'], function (exports, _show, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberCan.CanMixin, {
    tagName: '',
    _items: _show.ACTIONS,
    // Computed
    items: Ember.computed('_items', function () {
      var _this = this;

      var items = Ember.get(this, '_items'),
          array = [];

      items.forEach(function (_item) {
        var item = Ember.copy(_item),
            ability = Ember.get(item, 'ability');

        if (ability) {
          if (_this.can(Ember.get(item, 'ability'))) {
            array.pushObject(item);
          }
        } else {
          array.pushObject(item);
        }
      });

      return array;
    })
  });
});