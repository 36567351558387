define('distorted-portal/routes/internal/users/new', ['exports', 'distorted-portal/mixins/routes/rollbackable'], function (exports, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackable.default, {
    // Attributes
    queryParams: {
      clientId: {
        refreshModel: true
      },
      syndicatorId: {
        refreshModel: true
      }
    },
    // // Methods
    model: function model(params) {

      var data = {},
          currentUser = Ember.get(this, 'session.currentUser');

      if (params.clientId) {
        data.role = 'client';
        data.client = this.store.peekRecord('client', params.clientId);
      } else if (params.syndicatorId) {
        data.role = 'syndicator';
        data.syndicator = this.store.peekRecord('syndicator', params.syndicatorId);
      } else {
        data.role = Ember.get(currentUser, 'role');
        if (Ember.get(currentUser, 'isClient')) {
          data.client = Ember.get(this, 'session.currentUser.client');
        }
        if (Ember.get(currentUser, 'isSyndicator')) {
          data.syndicator = Ember.get(this, 'session.currentUser.syndicator');
        }
      }

      return this.store.createRecord('user', data);
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('clientId', null);
        controller.set('syndicatorId', null);
      }
    }
  });
});