define('distorted-portal/mixins/validations/syndicator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _buildValidations;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = (0, _emberCpValidations.buildValidations)((_buildValidations = {
    countryCode: [(0, _emberCpValidations.validator)('presence', true)],
    description: [(0, _emberCpValidations.validator)('presence', true)],
    facebook: [],
    notes: [],
    name: [(0, _emberCpValidations.validator)('presence', true)],
    city: [(0, _emberCpValidations.validator)('presence', true)],
    platform: [(0, _emberCpValidations.validator)('presence', true)],
    twitter: [],
    website: []
  }, _defineProperty(_buildValidations, 'notes', []), _defineProperty(_buildValidations, 'weeklyReach', [(0, _emberCpValidations.validator)('presence', true)]), _buildValidations));
});