define('distorted-portal/controllers/internal/client/dashboard', ['exports', 'distorted-portal/mixins/controllers/dashboardable'], function (exports, _dashboardable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dashboardable.default, {
    pageTitle: "Client Dashboard",
    // Controller
    clientController: Ember.inject.controller('internal.client')
  });
});