define('distorted-portal/components/filter-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    tagName: '',
    icon: 'filter_list',
    title: 'Filter',
    showFilter: false,
    // Actions
    actions: {
      toggleFilter: function toggleFilter() {
        this.toggleProperty("showFilter");
      }
    }
  });
});