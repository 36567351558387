define('distorted-portal/controllers/internal/clients/index', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/const/client'], function (exports, _paginatable, _searchable, _sortable, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Clients',
    sort: 'name',
    sortOptions: _client.SORT_OPTIONS,
    grid: true
  });
});