define('distorted-portal/mirage/factories/podcast', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    author: function author() {
      return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
    },
    copyright: function copyright() {
      return _emberCliMirage.faker.company.companyName() + ' ' + _emberCliMirage.faker.date.past().getYear();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraphs(2);
    },
    image: function image() {
      return _emberCliMirage.faker.image.imageUrl(320, 320, undefined, true);
    },
    iTunesOwnerName: function iTunesOwnerName() {
      return 'This Is Distorted';
    },
    iTunesOwnerEmail: function iTunesOwnerEmail() {
      return 'info@thisisdistorted.com';
    },
    isExplicit: function isExplicit() {
      return _emberCliMirage.faker.random.boolean();
    },
    keywords: function keywords() {
      return _emberCliMirage.faker.lorem.words();
    },
    subTitle: function subTitle() {
      return _emberCliMirage.faker.hacker.phrase();
    },
    summary: function summary() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    title: function title() {
      return (_emberCliMirage.faker.hacker.verb() + ' ' + _emberCliMirage.faker.hacker.noun()).capitalize();
    },
    totalEpisodes: function totalEpisodes() {
      return _emberCliMirage.faker.random.number({
        min: 0,
        max: 99
      });
    },
    xmlFeed: function xmlFeed() {
      return _emberCliMirage.faker.internet.url();
    },
    deletedAt: function deletedAt() {
      return null;
    },

    // Traits
    withClient: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(podcast, server) {
        var clients = server.schema.clients.all().models,
            client = _emberCliMirage.faker.random.arrayElement(clients);
        podcast.update({
          client: client
        });
      }
    }),
    withCategory: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(podcast, server) {
        var categories = server.schema.categories.all().models,
            category = _emberCliMirage.faker.random.arrayElement(categories);
        podcast.update({
          category: category
        });
      }
    }),
    withLanguage: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(podcast, server) {
        var languages = server.schema.languages.all().models,
            language = _emberCliMirage.faker.random.arrayElement(languages);
        podcast.update({
          language: language
        });
      }
    })
  });
});