define('distorted-portal/abilities/dashboard', ['exports', 'ember-can', 'distorted-portal/utils/const/user'], function (exports, _emberCan, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCan.Ability.extend({
    // Service
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    // Computed
    isAdmin: Ember.computed.equal('user.role', _user.ADMIN_ROLE),
    isClient: Ember.computed.equal('user.role', _user.CLIENT_ROLE),
    isSyndicator: Ember.computed.equal('user.role', _user.SYNDICATOR_ROLE),
    isAny: Ember.computed.or('isAdmin', 'isClient', 'isSyndicator'),
    isAdminOrClient: Ember.computed.or('isAdmin', 'isClient'),
    // only admins can read a dashboard
    canRead: Ember.computed.alias('isAdminOrClient')
  });
});