define('distorted-portal/components/form-image-upload', ['exports', 'distorted-portal/utils/get-api-url'], function (exports, _getApiUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, 'dropzone').destroy();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Find any classes within the current component that were inserted by Dropzone.
      // This signifies that Dropzone has been initialized.
      var dropzoneClass = this.$().find('.dz-default');

      // Only initialize dropzone if it hasn't been already.
      if (dropzoneClass.length !== 0) {
        return;
      }
      var options = Ember.get(this, 'options'),
          dropzone = new Dropzone("#dropzone-image", options),
          action = Ember.get(this, 'onUpload');

      // doing this here rather than in the config object
      // so we have easy access to the model, key & dropbox vars
      dropzone.on('complete', function (file) {
        var uploadSuccessful = file.xhr.status >= 200 && file.xhr.status < 300;
        if (uploadSuccessful) {
          var fileUrl = file.xhr.responseXML.querySelector("PostResponse Location").innerHTML;
          // set(model, key, fileUrl);
          if (action) {
            action(fileUrl);
          }
          dropzone.removeFile(file);
        }
      });

      Ember.set(this, 'dropzone', dropzone);
    },

    // Events
    addedFileEvent: function addedFileEvent() /*file*/{
      if (this.files.length > 1) {
        this.removeFile(this.files[0]);
      }
    },
    acceptEvent: function acceptEvent(file, cb) {
      var payload = {
        'file-name': file.name,
        'mime-type': file.type
      };
      // Make json call to api server to generate an S3 policy
      Ember.$.post((0, _getApiUrl.default)("/file_policy"), JSON.stringify(payload), function (response, textStatus) {

        if (textStatus != 'success') {
          cb(response.error);
          return;
        }
        file.policy = response; // see below for an example of what this object looks like
        cb(); // don't forget to call the callback, otherwise the upload will not start.
      }, 'json');
    },
    sendingEvent: function sendingEvent(file, xhr, formData) {
      for (var key in file.policy) {
        formData.append(key, file.policy[key]);
      }
    },

    // Computed
    image: Ember.computed.alias('model.image'),
    options: Ember.computed(function () {
      return {
        acceptedFiles: "image/png,image/jpg,image/jpeg",
        addRemoveLinks: false,
        clickable: true,
        createImageThumbnails: true,
        method: "post",
        paramName: "file",
        thumbnailWidth: null,
        thumbnailHeight: null,
        url: 'https://distorted-dev.s3.amazonaws.com/',
        maxFilesize: 12,
        maxFiles: 1,
        accept: this.acceptEvent,
        addedfile: this.addedFileEvent,
        sending: this.sendingEvent
      };
    })
  });
});