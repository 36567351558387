define('distorted-portal/mirage/factories/audio-group-file', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.system.directoryPath + _emberCliMirage.faker.system.fileName;
    },
    fileName: function fileName() {
      return _emberCliMirage.faker.system.fileName;
    },
    size: function size() {
      return _emberCliMirage.faker.random.number({
        min: 100000000,
        max: 999999999
      });
    }
  });
});