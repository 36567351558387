define('distorted-portal/routes/internal/syndicators/new', ['exports', 'distorted-portal/mixins/routes/rollbackable'], function (exports, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackable.default, {
    model: function model() {
      return this.store.createRecord('syndicator');
    }
  });
});