define('distorted-portal/services/aaanalytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    trackView: function trackView() /*view, title*/{
      // do nuthin - remove once ember-analytics-sauce is back
    },
    trackEvent: function trackEvent() /*event, data*/{
      // do nuthin - remove once ember-analytics-sauce is back
    }
  });
});