define('distorted-portal/utils/const/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ADMIN_ROLE = exports.ADMIN_ROLE = 'admin';
  var CLIENT_ROLE = exports.CLIENT_ROLE = 'client';
  var SYNDICATOR_ROLE = exports.SYNDICATOR_ROLE = 'syndicator';
  var ROLE_OPTIONS = exports.ROLE_OPTIONS = [ADMIN_ROLE, CLIENT_ROLE, SYNDICATOR_ROLE];
  var SORT_OPTIONS = exports.SORT_OPTIONS = [{
    label: 'Email',
    value: 'email'
  }, {
    label: 'First Name',
    value: 'firstName'
  }, {
    label: 'Surname',
    value: 'lastName'
  }];
  var ACTIONS = exports.ACTIONS = [{
    title: 'View User',
    route: 'internal.user.index',
    name: 'view',
    ability: 'read user'
  }, {
    title: 'Edit User',
    route: 'internal.user.edit',
    name: 'edit',
    ability: 'update user'
  }, {
    title: 'Change Password',
    route: 'internal.user.password',
    name: 'password',
    ability: 'update password user'
  }, {
    title: 'Archive User',
    route: 'internal.user.archive',
    name: 'archive',
    ability: 'archive user'
  }];
});