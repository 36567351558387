define('distorted-portal/routes/internal/shows/new', ['exports', 'distorted-portal/mixins/routes/rollbackable'], function (exports, _rollbackable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_rollbackable.default, {
    // Attributes
    queryParams: {
      programmeId: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) /*transition*/{
      var _this = this;

      var programme = this.store.peekRecord('programme', params.programmeId);

      // In most cases the programme will already exist in the ember data
      // cache. From a cold start however we will need to load from api
      if (!programme) {
        return this.store.findRecord('programme', params.programmeId).then(function (programme) {
          return _this.store.createRecord('episode', {
            summary: Ember.get(programme, 'summary'),
            description: Ember.get(programme, 'description')
          });
        });
      }
      return this.store.createRecord('show', {
        summary: Ember.get(programme, 'summary'),
        description: Ember.get(programme, 'description')
      });
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('programmeId', null);
      }
    }
  });
});