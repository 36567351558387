define('distorted-portal/controllers/internal/podcasts/trash', ['exports', 'distorted-portal/mixins/controllers/paginatable', 'distorted-portal/mixins/controllers/searchable', 'distorted-portal/mixins/controllers/sortable', 'distorted-portal/utils/const/podcast'], function (exports, _paginatable, _searchable, _sortable, _podcast) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatable.default, _searchable.default, _sortable.default, {
    pageTitle: 'Podcast Trash',
    sort: 'title',
    sortOptions: _podcast.SORT_OPTIONS,
    grid: true
  });
});