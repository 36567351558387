define('distorted-portal/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/audio-group-file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/audio-group-file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/audio-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/audio-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/episode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/episode.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/podcast.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/podcast.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/programme-syndicator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/programme-syndicator.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/programme.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/programme.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/show.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/show.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/syndicator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/syndicator.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/categories.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/categories.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/countries.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/countries.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/languages.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/languages.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/responses.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/responses.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/helpers/session-handlers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/helpers/session-handlers.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/audio-group-file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/audio-group-file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/audio-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/audio-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/country.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/country.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/episode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/episode.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/language.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/language.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/podcast.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/podcast.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/programme-syndicator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/programme-syndicator.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/programme.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/programme.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/show.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/show.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/syndicator.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/syndicator.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});