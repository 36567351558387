define('distorted-portal/routes/internal/programme', ['exports', 'distorted-portal/mixins/routes/authorisationable'], function (exports, _authorisationable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authorisationable.default, {
    // Attributes
    ability: 'read programme',
    queryParams: {
      clientId: {
        refreshModel: true
      }
    },
    // Methods
    model: function model(params) {
      return this.store.findRecord('programme', params.programme_id, {
        include: 'category,client,language',
        reload: true
      });
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('clientId', null);
      }
    }
  });
});