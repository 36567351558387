define('distorted-portal/components/uxs-tabs', ['exports', 'ember-ux-sauce/components/uxs-tabs'], function (exports, _uxsTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uxsTabs.default;
    }
  });
});